<template>
  <footer class="relative pt-20 pb-10 overflow-hidden">
    <!-- Background decorative elements -->
    <div class="absolute inset-0 -z-10">
      <div class="absolute left-1/4 bottom-0 w-80 h-80 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
      <div class="absolute right-1/4 bottom-0 w-80 h-80 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
    </div>
    
    <div class="container mx-auto px-6 md:px-8">
      <!-- Main footer content -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-10 lg:gap-8 mb-16">
        <!-- Logo and about section -->
        <div class="lg:col-span-2">
          <div class="flex items-center mb-5">
            <img :src="require('@/assets/logo.png')" alt="Logo" class="h-10 mr-3">
            <h2 class="text-2xl font-bold text-white">Bang Pateng</h2>
          </div>
          <p class="text-white/70 mb-5 max-w-md">
            Your comprehensive platform for blockchain testnet participation, validator setup guides, and airdrop opportunities. Join our community of node operators and blockchain enthusiasts.
          </p>
          
          <!-- Social icons -->
          <div class="flex space-x-4">
            <a href="https://t.me/bangpateng_airdrop" target="_blank" rel="noopener noreferrer" class="social-icon flex items-center justify-center w-10 h-10 rounded-full backdrop-blur-sm bg-white/5 border border-white/10 hover:bg-white/10 transition-all">
              <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.3-.6.3l.213-3.034 5.52-4.982c.24-.213-.054-.334-.373-.121l-6.826 4.297-2.917-.967c-.633-.197-.643-.638.134-.942l11.574-4.458c.548-.197 1.025.127.775.934z"/>
              </svg>
            </a>
            <a href="https://twitter.com/bangpateng_" target="_blank" rel="noopener noreferrer" class="social-icon flex items-center justify-center w-10 h-10 rounded-full backdrop-blur-sm bg-white/5 border border-white/10 hover:bg-white/10 transition-all">
              <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
            </a>
            <a href="https://youtube.com/c/bangpateng" target="_blank" rel="noopener noreferrer" class="social-icon flex items-center justify-center w-10 h-10 rounded-full backdrop-blur-sm bg-white/5 border border-white/10 hover:bg-white/10 transition-all">
              <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
              </svg>
            </a>
            <a href="https://github.com/bangpateng" target="_blank" rel="noopener noreferrer" class="social-icon flex items-center justify-center w-10 h-10 rounded-full backdrop-blur-sm bg-white/5 border border-white/10 hover:bg-white/10 transition-all">
              <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
              </svg>
            </a>
          </div>
        </div>
        
        <!-- Quick Links -->
        <div>
          <h3 class="text-white text-lg font-bold mb-5">Quick Links</h3>
          <ul class="space-y-3">
            <li>
              <router-link to="/" class="text-white/70 hover:text-white transition-colors">Home</router-link>
            </li>
            <li>
              <router-link to="/testnet" class="text-white/70 hover:text-white transition-colors">Testnets</router-link>
            </li>
            <li>
              <router-link to="/explorer" class="text-white/70 hover:text-white transition-colors">Explorer</router-link>
            </li>
            <li>
  <a href="https://www.youtube.com/watch?v=b3rOlxZKbDI&t=260s" target="_blank" rel="noopener noreferrer" 
     class="text-white/70 hover:text-white transition-colors">
    Tutorial Airdrop
  </a>
</li>
            <li>
              <router-link to="/about" class="text-white/70 hover:text-white transition-colors">About Us</router-link>
            </li>
          </ul>
        </div>
        
        <!-- Testnets -->
        <div>
          <h3 class="text-white text-lg font-bold mb-5">Testnet Categories</h3>
          <ul class="space-y-3">
            <li>
              <router-link to="/testnet/" class="text-white/70 hover:text-white transition-colors">Cosmos Ecosystem</router-link>
            </li>
          </ul>
        </div>
        
        <!-- Support -->
        <div>
          <h3 class="text-white text-lg font-bold mb-5">Support</h3>
          <ul class="space-y-3">
            <li>
              <router-link to="/contact" class="text-white/70 hover:text-white transition-colors">Contact Us</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy" class="text-white/70 hover:text-white transition-colors">Privacy Policy</router-link>
            </li>
            <li>
              <router-link to="/terms" class="text-white/70 hover:text-white transition-colors">Terms of Service</router-link>
            </li>
          </ul>
        </div>
      </div>
      
      <!-- Newsletter Subscription -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-6 md:p-8 mb-16">
        <div class="flex flex-col md:flex-row justify-between items-center">
          <div class="mb-6 md:mb-0 md:mr-8">
            <h3 class="text-xl font-bold text-white mb-2">Stay Updated</h3>
            <p class="text-white/70">Subscribe to our newsletter for the latest testnet opportunities and airdrop alerts</p>
          </div>
          
          <form @submit.prevent="subscribeNewsletter" class="w-full md:w-auto flex flex-col sm:flex-row gap-3">
            <input 
              type="email" 
              v-model="newsletterEmail" 
              placeholder="Your email address" 
              class="w-full sm:w-80 px-4 py-3 rounded-full bg-white/10 backdrop-blur-sm border border-white/10 text-white placeholder-white/40 focus:outline-none focus:border-[#efdf8a]/50 focus:ring-1 focus:ring-[#efdf8a]/50"
              required
            >
            <button 
              type="submit" 
              class="px-6 py-3 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
      
      <!-- Copyright and Bottom Links -->
      <div class="border-t border-white/10 pt-8 flex flex-col md:flex-row justify-between items-center">
        <p class="text-white/50 text-sm mb-4 md:mb-0">
          © {{ new Date().getFullYear() }} Bang Pateng All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
      newsletterEmail: ''
    }
  },
  methods: {
    subscribeNewsletter() {
      // Implement newsletter subscription logic here
      console.log('Newsletter subscription for:', this.newsletterEmail);
      alert('Thank you for subscribing to our newsletter!');
      this.newsletterEmail = '';
    }
  }
}
</script>

<style scoped>
.social-icon:hover {
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .grid-cols-5 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
}
</style>