export const dillMarkdown = `### Download File Binary

\`\`\`sh
curl -O https://dill-release.s3.ap-southeast-1.amazonaws.com/linux/dill.tar.gz
\`\`\`

\`\`\`sh
tar -xzvf dill.tar.gz && cd dill
\`\`\`

\`\`\`sh
./dill_validators_gen new-mnemonic --num_validators=1 --chain=andes --folder=./
\`\`\`

- Pilih English 2x
- Buat Katasandi dan Isi 2500
- Simpan Pharse
- Masukan Phase di VPS

\`\`\`sh
./validator_keys
\`\`\`

\`\`\`sh
./dill-node accounts import --andes --wallet-dir ./keystore --keys-dir validator_keys/ --accept-terms-of-use
\`\`\`

\`\`\`sh
echo <my-password> > walletPw.txt
\`\`\`

My-Password Ganti Dengan Password Kalian

### Start Light Node

\`\`\`sh
./start_light.sh -p walletPw.txt
\`\`\`

### Check Logs Node

\`\`\`sh
ps -ef | grep dill
\`\`\`

### Backup Wallet Json

\`\`\`sh
~/dill$ cat ./validator_keys/deposit_data-xxxx.json
\`\`\`

### Claim Faucet on Discord


- Discord : [Discord](https://discord.com/channels/1236933703251394570/1260145654509076542)
- Visit : [https://staking.dill.xyz/](https://staking.dill.xyz/)
- Connect EVM Wallet Metamask
- Import Your Data Validator Keys Json ~/dill$ cat ./validator_keys/deposit_data-xxxx.json
- Send Deposit and Approve Metamask
- Done

### Check Your Node

Paste Your Publickey : [https://andes.dill.xyz/validators](https://andes.dill.xyz/validators) `;