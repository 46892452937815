// Example Postingan Untuk Isi Command Command

export const gensynMarkdown = `### Install Node

\`\`\`sh
bash <(curl -s https://upload.bangpateng.xyz/uploads/gensyn.sh)
\`\`\`

- Ketik Y dan Enter

\`\`\`sh
screen -r gensyn
\`\`\`

- Ketik Y
- Masuk Screen 'screen -r gensyn'
- Buka di Browser : http://Your-IP-VPS:3000/
- Login Via Email
- Verifikasi Code Email (Jika OTP Gak Masuk Bisa Liat Tutor Paling Bawah)
- Back To Terminal VPS
- Save userData.json found. Proceeding...
ORG_ID set to: d5xxx52c-7290-4bd4-a4eb-cxxxxxxxx
- Would you like to push models you train in the RL swarm to the Hugging Face Hub? [y/N] (Pilih N dan Enter)
- di Bagian Ini ada Nama Node Anda Copy Saja

\`\`\`sh
#Contoh Yang Harus di Copy Yang Kamu Dapatkan di Terminal (Back Up Saja)
INFO:hivemind_exp.runner.grpo_runner:🐱 Hello 🐈 [blablabla] 🦮 [QmYhFgH3L57xxxxxxxxxxxxTRsrzd6Xdz4V2NS]!
INFO:hivemind_exp.runner.gensyn.testnet_grpo_runner:Registering self with peer ID: QmYhFgH3Lxxxxxxxxxxxxxxxxxxxxxz4V2NS
\`\`\`

- Save Screen CTRL + A + D Enter

<br/>

### JIKA OTP EMAIL TIDAK MASUK LAKUKAN INI (Optional)
<br/>

- Buka Tab Terminal Baru VPS Kalian
- Install

\`\`\`sh
wget https://bin.equinox.io/c/bNyj1mQVY4c/ngrok-v3-stable-linux-amd64.tgz && tar -xvzf ngrok-v3-stable-linux-amd64.tgz && sudo mv ngrok /usr/local/bin/
\`\`\`

- Register Via Email : https://ngrok.com/
- Skip
- Go to https://dashboard.ngrok.com/get-started/setup/linux
- Copy Your Token Paste Ke Terminal VPS
- Paste Juga ke VPS Kamu
\`\`\`sh
ngrok http 3000
\`\`\`
- Buka Link Yang di Hasilkan : (Example) https://7576-554-777-76-50.ngrok-free.app/
- Register Gensyn Lagi
- Masukan Email
- Verifikasi Code
- Backup File .pem : /root/rl-swarm/swarm.pem
- Done

<br/>

Check Your Node Name (Tunggu Beberapa Waktu Agar Terdeteksi) : https://dashboard.gensyn.ai/
`;



