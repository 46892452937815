<template>
  <nav class="navbar-container">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <!-- Logo -->
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="@/assets/logo.png" class="h-9" alt="Bang Pateng Logo" />
      </a>

      <!-- Mobile Menu Button -->
      <button
        @click="toggleMenu"
        type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden hover:bg-white/10 text-white backdrop-blur-md bg-white/5 border border-white/10"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>

      <!-- Navbar Items -->
      <div
        :class="menuOpen ? 'block' : 'hidden'"
        class="w-full md:block md:w-auto"
      >
        <ul
          class="flex flex-col font-medium p-5 md:p-0 mt-4 rounded-xl bg-black/60 backdrop-blur-md md:backdrop-blur-none md:space-x-12 rtl:space-x-reverse md:flex-row md:mt-0 md:bg-transparent"
        >
          <li v-for="(item, index) in navItems" :key="index">
            <a
              :href="item.path"
              class="nav-link block py-3 px-4 md:py-2 md:px-3 rounded transition-all"
              :class="{ 'active-link': item.isActive }"
            >
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: {
    currentRoute: {
      type: String,
      default: '/'
    }
  },
  setup(props) {
    const menuOpen = ref(false);

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    const navItems = computed(() => {
      return [
        { title: 'Home', path: '/', isActive: props.currentRoute === '/' },
        { title: 'Testnet', path: '/testnet', isActive: props.currentRoute === '/testnet' },
        { 
  title: 'Explorer', 
  path: 'https://ping.bangpateng.xyz/', 
  isActive: props.currentRoute === '/explorer',
  isExternal: true  // tambahkan flag ini untuk menandakan link eksternal
},
        { title: 'About', path: '/about', isActive: props.currentRoute === '/about' },
        { title: 'Contact', path: '/contact', isActive: props.currentRoute === '/contact' },
        { title: 'Status', path: '/status', isActive: props.currentRoute === '/status' }
      ];
    });

    return { menuOpen, toggleMenu, navItems };
  },
};
</script>

<style scoped>
.navbar-container {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  z-index: 50;
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5rem;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.nav-link {
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  transition: all 0.3s ease;
  font-size: 1.05rem;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 1);
}

.active-link {
  color: #efdf8a;
  font-weight: 500;
}

.active-link::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background: #efdf8a;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .navbar-container {
    width: calc(100% - 2.5rem);
    top: 1.25rem;
    border-radius: 1.5rem;
    padding: 0.75rem 1.25rem;
  }
  
  .nav-link {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  
  .active-link::after {
    bottom: 0;
    left: 1rem;
    transform: none;
  }
}
</style>