<template>
  <section class="about-us-section py-16 md:py-24 mt-16 md:mt-20 px-6 relative overflow-hidden">
    <!-- Background decorative elements -->
    <div class="absolute inset-0 -z-10">
      <div class="absolute left-0 top-1/3 w-72 h-72 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
      <div class="absolute right-0 bottom-1/3 w-80 h-80 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
    </div>
    
    <div class="container mx-auto max-w-5xl">
      <!-- Breadcrumb -->
      <nav class="text-white/70 text-sm mb-10">
        <router-link to="/" class="hover:text-blue-400 transition">Home</router-link> › 
        <span class="text-white font-semibold">About Us</span>
      </nav>
      
      <!-- Main header -->
      <div class="text-center mb-16">
        <h1 class="text-4xl md:text-5xl font-bold text-white mb-6">
          About <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581]">Bang Pateng</span>
        </h1>
        <p class="text-white/80 text-lg max-w-3xl mx-auto leading-relaxed">
          Your trusted platform for blockchain testnet participation, validator setup guides, and potential airdrop opportunities.
        </p>
      </div>
      
      <!-- Our Story Section -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 mb-12">
        <div class="flex flex-col md:flex-row gap-8 md:gap-12 items-center">
          <div class="md:w-2/5">
            <img :src="require('@/assets/img-web/network.svg')" alt="Blockchain Network" class="rounded-lg w-full h-auto shadow-lg">
          </div>
          
          <div class="md:w-3/5">
            <h2 class="text-2xl font-bold text-white mb-4 flex items-center">
              <svg class="w-6 h-6 mr-2 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/>
              </svg>
              Our Story
            </h2>
            
            <p class="text-white/80 mb-4 leading-relaxed">
              Founded in 2014, Bang Pateng Community began as a community-driven project to simplify the complex world of blockchain testing. What started as a small initiative sharing validator setup guides has grown into a comprehensive platform helping thousands of users participate in exciting blockchain projects.
            </p>
            
            <p class="text-white/80 leading-relaxed">
              Today, we're proud to serve a global community of node operators and blockchain enthusiasts, continually expanding our offerings to meet the evolving needs of the Web3 ecosystem.
            </p>
          </div>
        </div>
      </div>
      
      <!-- Our Mission & Vision -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <!-- Mission -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 h-full">
          <h2 class="text-2xl font-bold text-white mb-4 flex items-center">
            <svg class="w-6 h-6 mr-2 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/>
            </svg>
            Our Mission
          </h2>
          
          <p class="text-white/80 leading-relaxed">
            Our mission is to democratize access to blockchain testing environments by providing high-quality educational resources, step-by-step guides, and tools that empower individuals to participate in the early stages of blockchain development.
          </p>
          
          <ul class="mt-4 space-y-2">
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Simplify complex technical processes</span>
            </li>
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Bridge knowledge gaps for newcomers</span>
            </li>
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Foster a collaborative community</span>
            </li>
          </ul>
        </div>
        
        <!-- Vision -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 h-full">
          <h2 class="text-2xl font-bold text-white mb-4 flex items-center">
            <svg class="w-6 h-6 mr-2 text-[#9ccdee]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M9.4 10.5l4.77-8.26C13.47 2.09 12.75 2 12 2c-2.4 0-4.6.85-6.32 2.25l3.66 6.35.06-.1zM21.54 9c-.92-2.92-3.15-5.26-6-6.34L11.88 9h9.66zm.26 1h-7.49l.29.5 4.76 8.25C21 16.97 22 14.61 22 12c0-.69-.07-1.35-.2-2zM8.54 12l-3.9-6.75C3.01 7.03 2 9.39 2 12c0 .69.07 1.35.2 2h7.49l-1.15-2zm-6.08 3c.92 2.92 3.15 5.26 6 6.34L12.12 15H2.46zm11.27 0l-3.9 6.76c.7.15 1.42.24 2.17.24 2.4 0 4.6-.85 6.32-2.25l-3.66-6.35-.93 1.6z"/>
            </svg>
            Our Vision
          </h2>
          
          <p class="text-white/80 leading-relaxed">
            We envision a future where participating in blockchain testnets and running validator nodes is accessible to everyone, regardless of their technical background. By lowering barriers to entry, we aim to contribute to a more diverse, resilient, and innovative blockchain ecosystem.
          </p>
          
          <ul class="mt-4 space-y-2">
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Expand blockchain participation globally</span>
            </li>
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Support the next generation of Web3 builders</span>
            </li>
            <li class="flex items-start gap-2">
              <svg class="w-5 h-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
              </svg>
              <span class="text-white/80">Become the definitive resource for testnet participation</span>
            </li>
          </ul>
        </div>
      </div>
      
      <!-- Meet Our Team (Single Member) -->
      <div class="mb-16">
        <h2 class="text-2xl font-bold text-white mb-8 text-center">Meet Our Founder</h2>
        
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 max-w-xl mx-auto">
          <div class="flex flex-col items-center">
            <div class="w-32 h-32 rounded-full overflow-hidden mx-auto mb-6 border-2 border-[#efdf8a]/30">
              <img :src="require('@/assets/image-team/bangpateng.png')" alt="Founder" class="w-full h-full object-cover">
            </div>
            
            <h3 class="text-2xl font-semibold text-white mb-2">Bang Pateng</h3>
            <p class="text-[#efdf8a] mb-4 text-lg">Founder & Lead Developer</p>
            
            <p class="text-white/80 text-center max-w-lg mb-6 leading-relaxed">
              Blockchain developer with over 5 years of experience in validator operations and network security. Passionate about making blockchain technology accessible to everyone through education and community building.
            </p>
            
            <div class="flex justify-center space-x-6">
              <a href="https://twitter.com/bangpateng_" target="_blank" class="text-white/60 hover:text-[#efdf8a] transition-colors">
                <svg class="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </a>
              <a href="https://github.com/bangpateng" target="_blank" class="text-white/60 hover:text-[#efdf8a] transition-colors">
                <svg class="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.342-3.369-1.342-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.202 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.933.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.416 22 12c0-5.523-4.477-10-10-10z"/>
                </svg>
              </a>
              <a href="https://t.me/bang_pateng" target="_blank" class="text-white/60 hover:text-[#efdf8a] transition-colors">
                <svg class="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M9.78 18.65L10.06 14.42L17.74 7.5C18.08 7.19 17.67 7.04 17.22 7.31L7.74 13.3L3.64 12C2.76 11.75 2.75 11.14 3.84 10.7L19.81 4.54C20.54 4.21 21.24 4.72 20.96 5.84L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.78 18.65Z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Join Us CTA -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 text-center">
        <h2 class="text-2xl font-bold text-white mb-4">Join Our Community</h2>
        <p class="text-white/80 max-w-2xl mx-auto mb-6">
          Become part of our growing community of blockchain enthusiasts and node operators. Share knowledge, get support, and stay updated on the latest testnet opportunities.
        </p>
        <div class="flex flex-wrap justify-center gap-4">
          <a href="https://t.me/bangpateng_airdrop" target="_blank" class="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all">
            Join Telegram
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M9.78 18.65L10.06 14.42L17.74 7.5C18.08 7.19 17.67 7.04 17.22 7.31L7.74 13.3L3.64 12C2.76 11.75 2.75 11.14 3.84 10.7L19.81 4.54C20.54 4.21 21.24 4.72 20.96 5.84L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.78 18.65Z"/>
            </svg>
          </a>
          <a href="https://discord.gg/bangpateng" target="_blank" class="inline-flex items-center gap-2 px-6 py-3 backdrop-blur-sm bg-white/5 border border-white/10 text-white hover:bg-white/10 font-medium rounded-full transition-all">
            Join Discord
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 00-.079.036c-.21.385-.39.779-.53 1.162-1.796-.27-3.593-.27-5.325 0-.14-.38-.32-.777-.53-1.162a.077.077 0 00-.079-.036c-1.714.29-3.354.8-4.885 1.491a.07.07 0 00-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 00.03.057 20.03 20.03 0 006.031 3.056.077.077 0 00.084-.028c.462-.63.874-1.295 1.226-1.995a.076.076 0 00-.041-.106 13.209 13.209 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.372.292a.077.077 0 01-.006.127 12.412 12.412 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.963 19.963 0 006.032-3.055.077.077 0 00.032-.057c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.029z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUsComponent'
}
</script>

<style scoped>
/* Add any additional custom styles here */
</style>