export const symphonyMarkdown = `<div class="tab-container">
  <div class="tab-nav">
    <button class="tab-btn active" onclick="openTab(event, 'installation')">Installation</button>
    <button class="tab-btn" onclick="openTab(event, 'upgrade')">Upgrade</button>
    <button class="tab-btn" onclick="openTab(event, 'sync')">Sync</button>
    <button class="tab-btn" onclick="openTab(event, 'public-api')">Public API</button>
    <button class="tab-btn" onclick="openTab(event, 'cli-cheatsheet')">CLI Cheatsheet</button>
  </div>

<div id="installation" class="tab-content active">

### Auto Install

\`\`\`sh
wget https://raw.githubusercontent.com/bangpateng/symphony/refs/heads/main/symphony.sh && chmod +x symphony.sh && ./symphony.sh
\`\`\`

### Manual Install

Install Dependencies

\`\`\`sh
sudo apt update && sudo apt upgrade -y
sudo apt install curl iptables build-essential git wget jq make gcc nano tmux htop nvme-cli pkg-config libssl-dev libleveldb-dev tar clang bsdmainutils ncdu unzip libleveldb-dev aria2 -y
\`\`\`

Install Go

\`\`\`sh
VER="1.21.6"
sudo rm -rf /usr/local/go
curl -Ls https://go.dev/dl/go$VER.linux-amd64.tar.gz | sudo tar -xzf - -C /usr/local
echo "export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin" >> $HOME/.bash_profile
source $HOME/.bash_profile
go version
\`\`\`

Install Binary

\`\`\`sh
cd $HOME
git clone https://github.com/Orchestra-Labs/symphony
cd symphony
git checkout v0.4.1
make install
\`\`\`

Initialize The Node

\`\`\`sh
symphonyd init $MONIKER --chain-id symphony-testnet-4
sed -i -e "s|^keyring-backend *=.*|keyring-backend = \\"os\\"|" $HOME/.symphonyd/config/client.toml
sed -i -e "s|^chain-id *=.*|chain-id = \\"symphony-testnet-4\\"|" $HOME/.symphonyd/config/client.toml
sed -i -e "s|^node *=.*|node = \\"tcp://localhost:12157\\"|" $HOME/.symphonyd/config/client.toml
\`\`\`

Download Genesis & Addrbook

\`\`\`sh
curl -L https://snapshot-symphony.bangpateng.xyz/genesis.json > $HOME/.symphonyd/config/genesis.json
curl -L https://snapshot-symphony.bangpateng.xyz/addrbook.json > $HOME/.symphonyd/config/addrbook.json
\`\`\`

Configure Seeds and Peers

\`\`\`sh
SEEDS="354d05a86bb1649f680a1c038f17f7f0ea83b4cc@symphony-bangpateng.xyz:12156"
PEERS="$(curl -sS https://rpc-symphony.bangpateng.xyz/net_info | jq -r '.result.peers[] | "\\(.node_info.id)@\\(.remote_ip):\\(.node_info.listen_addr)"' | awk -F ':' '{print $1":"$(NF)}' | sed -z 's|\n|,|g;s|.$||')"
sed -i -e "s|^seeds *=.*|seeds = '"$SEEDS"'|; s|^persistent_peers *=.*|persistent_peers = '"$PEERS"'|" $HOME/.symphonyd/config/config.toml
\`\`\`

Update Port Configuration

\`\`\`sh
sed -i -e "s%:1317%:12117%; s%:8080%:12180%; s%:9090%:12190%; s%:9091%:12191%; s%:8545%:12145%; s%:8546%:12146%; s%:6065%:12165%" $HOME/.symphonyd/config/app.toml
sed -i -e "s%:26658%:12158%; s%:26657%:12157%; s%:6060%:12160%; s%:26656%:12156%; s%:26660%:12161%" $HOME/.symphonyd/config/config.toml
\`\`\`

Customize Pruning

\`\`\`sh
sed -i \\
  -e 's|^pruning *=.*|pruning = "custom"|' \\
  -e 's|^pruning-keep-recent *=.*|pruning-keep-recent = "100"|' \\
  -e 's|^pruning-interval *=.*|pruning-interval = "17"|' \\
  $HOME/.symphonyd/config/app.toml
\`\`\`

Set Minimum Gas Price, Enable Prometheus, and Disable the Indexer

\`\`\`sh
sed -i -e "s|^minimum-gas-prices *=.*|minimum-gas-prices = \\"0.25note\\"|" $HOME/.symphonyd/config/app.toml
sed -i -e "s/prometheus = false/prometheus = true/" $HOME/.symphonyd/config/config.toml
sed -i -e "s/^indexer *=.*/indexer = \\"null\\"/" $HOME/.symphonyd/config/config.toml
\`\`\`

Create Service File

\`\`\`sh
sudo tee /etc/systemd/system/symphonyd.service > /dev/null &lt;&lt;EOF
[Unit]
Description=symphony node service
After=network-online.target
[Service]
User=$USER
ExecStart=$(which symphonyd) start
Restart=always
RestartSec=3
LimitNOFILE=65535
[Install]
WantedBy=multi-user.target
EOF
\`\`\`

Download Current Snapshot

\`\`\`sh
curl "https://snapshot-symphony.bangpateng.xyz/latest.tar.lz4" | lz4 -dc - | tar -xf - -C "$HOME/.symphonyd"
\`\`\`

Enable the Service and Start the Node

\`\`\`sh
sudo systemctl daemon-reload
sudo systemctl enable symphonyd.service
sudo systemctl restart symphonyd.service && sudo journalctl -u symphonyd.service -f --no-hostname -o cat
\`\`\`

</div>

<div id="upgrade" class="tab-content">

\`\`\`sh
None
\`\`\`

</div>

<div id="sync" class="tab-content">

Peer

\`\`\`sh
None
\`\`\`

Snapshoot

\`\`\`sh
None
\`\`\`

Addrbook

\`\`\`sh
None
\`\`\`

Genesis

\`\`\`sh
None
\`\`\`

</div>

<div id="public API" class="tab-content">

API

\`\`\`sh
http://api-symphony.bangpateng.xyz/
\`\`\`

RPC

\`\`\`sh
http://rpc-symphony.bangpateng.xyz/
\`\`\`

</div>

<div id="CLI Cheatsheet" class="tab-content">

### Wallet Management

Create New Wallet

\`\`\`sh
symphonyd keys add 
\`\`\`

Recovery New Wallet

\`\`\`sh
symphonyd keys add  --recover
\`\`\`

List All Wallet

\`\`\`sh
symphonyd keys list 
\`\`\`

Delete Wallet

\`\`\`sh
symphonyd keys delete 
\`\`\`

Export Wallet

\`\`\`sh
symphonyd keys export 
\`\`\`

Import key

\`\`\`sh
symphonyd keys import .backup
\`\`\`

Check balance

\`\`\`sh
symphonyd q bank balances $(symphonyd keys show  -a)
\`\`\`

### Validator Management

Create Validator

\`\`\`sh
symphonyd tx staking create-validator \\
--amount=YOUR-AMOUNTumld \\
--pubkey=$(symphonyd tendermint show-validator) \\
--moniker="YOUR-NAME-MONIKER" \\
--identity="YOUR-KEYBASE-ID" \\
--details="YOUR-DETAIL-VALIDATOR" \\
--website="YOUR-WEBSITE" \\
--security-contact=YOUR-CONTACT \\
--chain-id=symphony-4 \\
--commission-rate=0.05 \\
--commission-max-rate=0.20 \\
--commission-max-change-rate=0.01 \\
--min-self-delegation=1 \\
--gas-prices 0umld \\
--gas "auto" \\
--gas-adjustment "1.5" \\
--from wallet \\
-y
\`\`\`

Edit Validator

\`\`\`sh
symphonyd tx staking create-validator \\
--amount=YOUR-AMOUNTuatone \\
--pubkey=$(symphonyd tendermint show-validator) \\
--moniker="YOUR-NAME-MONIKER" \\
--identity="YOUR-KEYBASE-ID" \\
--details="YOUR-DETAIL-VALIDATOR" \\
--website="YOUR-WEBSITE" \\
--security-contact=YOUR-CONTACT \\
--chain-id=symphony-4 \\
--commission-rate=0.05 \\
--commission-max-rate=0.20 \\
--commission-max-change-rate=0.01 \\
--min-self-delegation=1 \\
--gas-prices 0umld \\
--gas "auto" \\
--gas-adjustment "1.5" \\
--from wallet \\
-y
\`\`\`

Unjail Validator

\`\`\`sh
symphonyd tx slashing unjail --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5  
\`\`\`

Jail Reason

\`\`\`sh
symphonyd query slashing signing-info $(symphonyd tendermint show-validator)
\`\`\`

View validator details

\`\`\`sh
symphonyd q staking validator $(symphonyd keys show wallet --bech val -a)
\`\`\`

Get Denom Info

\`\`\`sh
symphonyd q bank denom-metadata -oj | jq
\`\`\`

Get Sync Status

\`\`\`sh
symphonyd status 2>&1 | jq -r '.SyncInfo.catching_up // .sync_info.catching_up'
\`\`\`

Get Latest Height

\`\`\`sh
symphonyd status 2>&1 | jq -r '.SyncInfo.latest_block_height // .sync_info.latest_block_height'
\`\`\`

### Token Management

Withdraw rewards from all validators

\`\`\`sh
symphonyd tx distribution withdraw-all-rewards --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y 
\`\`\`

Withdraw commission and rewards from your validator

\`\`\`sh
symphonyd tx distribution withdraw-rewards $(symphonyd keys show wallet --bech val -a) --commission --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y 
\`\`\`

Delegate tokens to yourself

\`\`\`sh
symphonyd tx staking delegate $(symphonyd keys show wallet --bech val -a) 1000000umld --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y 
\`\`\`

Redelegate tokens to another validator

\`\`\`sh
symphonyd tx staking redelegate $(symphonyd keys show wallet --bech val -a) YOUR-VALOPER-ADDRESS 1000000umld --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y
\`\`\`

Delegate tokens to validator

\`\`\`sh
symphonyd tx staking delegate YOUR-VALOPER-ADDRESS 1000000umld --from  --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y 
\`\`\`

Unbond tokens from your validator

\`\`\`sh
symphonyd tx staking unbond $(symphonyd keys show wallet --bech val -a) 1000000umld --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y
\`\`\`

Send tokens to Any wallet

\`\`\`sh
symphonyd tx bank send wallet YOUR-ADDRESS-WALLET 1000000umld --from wallet --chain-id symphony-4 --gas auto --gas-adjustment 1.5 --gas-prices 0umld -y
\`\`\`

### Service Management

Reload Services

\`\`\`sh
sudo systemctl daemon-reload
\`\`\`

Enable Service

\`\`\`sh
sudo systemctl enable symphonyd 
\`\`\`

Disable Service

\`\`\`sh
sudo systemctl disable symphonyd 
\`\`\`

Run Service

\`\`\`sh
sudo systemctl start symphonyd 
\`\`\`

Stop Service

\`\`\`sh
sudo systemctl stop symphonyd 
\`\`\`

Restart Service

\`\`\`sh
sudo systemctl restart symphonyd 
\`\`\`

Check Service Status

\`\`\`sh
sudo systemctl status symphonyd 
\`\`\`

Check Service Logs

\`\`\`sh
sudo journalctl -u symphonyd -f --no-hostname -o cat
\`\`\`

### Remove Node

\`\`\`sh
cd $HOME && sudo systemctl stop symphonyd && sudo systemctl disable symphonyd && sudo rm /etc/systemd/system/symphonyd.service && sudo systemctl daemon-reload && sudo rm -rf $(which symphonyd) && sudo rm -rf $HOME/.symphony && sudo rm -rf $(which symphonyd)
\`\`\`

</div>
</div>`;