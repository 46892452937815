<template>
  <section class="partnership-section relative py-20 md:py-28">
    <!-- Background decorative elements -->
    <div class="absolute inset-0 -z-10">
      <div class="absolute left-1/4 top-10 w-64 h-64 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
      <div class="absolute right-1/4 bottom-10 w-64 h-64 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
    </div>
    
    <div class="container mx-auto px-6 md:px-8">
      <!-- Section header -->
      <div class="text-center mb-16">
        <h2 class="text-3xl md:text-4xl font-bold mb-4 text-white">
          Our <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#9ccdee] to-[#b4bfeb]">Partners</span> & Collaborations
        </h2>
        <p class="text-white/70 text-lg max-w-2xl mx-auto">
          We proudly work with leading blockchain projects and networks to provide the most accurate and up-to-date testnet information
        </p>
      </div>
      
      <!-- Partners grid with hover effects -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 md:p-12">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 md:gap-12">
          <template v-for="(partner, index) in partners" :key="index">
            <a 
              :href="partner.url" 
              target="_blank" 
              rel="noopener noreferrer" 
              class="partner-logo-container group"
            >
              <div class="partner-logo backdrop-blur-sm bg-white/5 border border-white/10 rounded-xl p-4 md:p-6 flex items-center justify-center h-32 transition-all duration-300 group-hover:border-[#efdf8a]/30 group-hover:bg-white/10 group-hover:shadow-lg">
                <img 
                  :src="partner.logo" 
                  :alt="partner.name" 
                  class="max-h-16 max-w-full transition-all duration-300 group-hover:scale-110 filter saturate-0 group-hover:saturate-100"
                />
                
                <!-- Tooltip on hover -->
                <div class="tooltip opacity-0 absolute -bottom-12 left-1/2 transform -translate-x-1/2 px-3 py-1.5 bg-gray-900/90 text-white text-xs rounded-lg whitespace-nowrap pointer-events-none transition-opacity duration-200 group-hover:opacity-100">
                  {{ partner.name }}
                </div>
              </div>
            </a>
          </template>
        </div>
      </div>
      
      <!-- Featured partner - can be conditionally rendered -->
      <div v-if="featuredPartner" class="mt-16 backdrop-blur-md bg-white/5 border border-white/10 border-[#efdf8a]/20 rounded-2xl p-8 md:p-10">
        <div class="flex flex-col md:flex-row items-center gap-8">
          <div class="md:w-1/3 flex justify-center">
            <div class="w-48 h-48 rounded-xl backdrop-blur-md bg-white/5 border border-white/10 p-6 flex items-center justify-center">
              <img :src="featuredPartner.logo" :alt="featuredPartner.name" class="max-w-full max-h-32">
            </div>
          </div>
          
          <div class="md:w-2/3">
            <div class="flex items-center gap-2">
              <a :href="featuredPartner.url" target="_blank" rel="noopener noreferrer" class="inline-flex items-center gap-2 px-5 py-2.5 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all duration-300">
                Visit Website
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
              <a :href="featuredPartner.testnetUrl" class="inline-flex items-center gap-2 px-5 py-2.5 backdrop-blur-sm bg-white/5 border border-white/10 text-white hover:bg-white/10 font-medium rounded-full transition-all duration-300">
                View Testnet
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PartnershipSection',
  data() {
    return {
      partners: [
        {
          name: 'Bitget',
          logo: require('@/assets/patner/bitget.svg'),
          url: 'https://bitget.com'
        },
        {
          name: 'Huobi',
          logo: require('@/assets/patner/huobi.svg'),
          url: 'https://huobi.com'
        },
        {
          name: 'Kucoin',
          logo: require('@/assets/patner/kucoin.svg'),
          url: 'https://kucoin.com'
        },
        {
          name: 'Okx',
          logo: require('@/assets/patner/okx.svg'),
          url: 'https://www.okx.com'
        },
        {
          name: 'Github',
          logo: require('@/assets/patner/github.svg'),
          url: 'https://github.com'
        },
        {
          name: 'Binance',
          logo: require('@/assets/patner/binance.svg'),
          url: 'https://binance.com'
        }
      ],
    }
  }
}
</script>

<style scoped>
.partner-logo-container {
  position: relative;
}

.partner-logo img {
  /* Default grayscale, becomes colored on hover */
  filter: brightness(0.9) grayscale(0.8);
}

.partner-logo:hover img {
  filter: brightness(1) grayscale(0);
}

@media (max-width: 768px) {
  .tooltip {
    display: none; /* Hide tooltips on mobile to prevent layout issues */
  }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.featured-animation {
  animation: float 6s ease-in-out infinite;
}
</style>