<template>
    <section class="node-status-section py-16 md:py-24 mt-20 md:mt-24 px-6 relative overflow-hidden">
      <!-- Background decorative elements -->
      <div class="absolute inset-0 -z-10">
        <div class="absolute left-0 top-1/3 w-72 h-72 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
        <div class="absolute right-0 bottom-1/3 w-80 h-80 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
      </div>
      
      <div class="container mx-auto max-w-5xl">
        <!-- Breadcrumb -->
        <nav class="text-white/70 text-sm mb-10">
          <router-link to="/" class="hover:text-blue-400 transition">Home</router-link> › 
          <span class="text-white font-semibold">Node Status</span>
        </nav>
        
        <div class="node-status-component">
          <div class="status-container">
            <div class="status-header">
              <div class="project-info">
                <h2 class="project-name">{{ projectName }}</h2>
              </div>
              <div class="status-time">{{ getCurrentTime() }}</div>
            </div>
  
            <div class="networks-container">
              <!-- Mainnet Section -->
              <div class="network-section">
                <div class="network-title">Mainnet</div>
                <div class="network-nodes">
                  <div 
                    v-for="(node, index) in mainnetNodes" 
                    :key="`mainnet-${index}`"
                    class="node-item"
                  >
                    <div class="node-status" :class="{ 'active': node.active }">
                      <div class="status-dot"></div>
                      <div class="node-name">{{ node.name }}</div>
                    </div>
                    <div class="status-indicator">
                      <div class="pulse-container" v-if="node.active">
                        <div class="pulse-bar" v-for="n in 8" :key="n"></div>
                      </div>
                      <div class="inactive-text" v-else>Inactive</div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Testnet Section -->
              <div class="network-section">
                <div class="network-title">Testnet</div>
                <div class="network-nodes">
                  <div 
                    v-for="(node, index) in testnetNodes" 
                    :key="`testnet-${index}`"
                    class="node-item"
                  >
                    <div class="node-status" :class="{ 'active': node.active }">
                      <div class="status-dot"></div>
                      <div class="node-name">{{ node.name }}</div>
                    </div>
                    <div class="status-indicator">
                      <div class="pulse-container" v-if="node.active">
                        <div class="pulse-bar" v-for="n in 8" :key="n"></div>
                      </div>
                      <div class="inactive-text" v-else>Inactive</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "NodeStatusComponent",
    
    props: {
        testnetNodes: {
        type: Array,
        default: () => [
          { name: "T3rn", active: true },
          { name: "Soundness", active: true },
          { name: "Symphony", active: true },
          { name: "Dill", active: true },
          { name: "Intento", active: true },
          { name: "Arichain", active: true }
        ]
      },
      mainnetNodes: {
        type: Array,
        default: () => [
          { name: "celestia", active: true },
        ]
      }
    },
    
    methods: {
      getCurrentTime() {
        const now = new Date();
        const options = { 
          day: 'numeric',
          month: 'long', 
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        };
        return now.toLocaleDateString('en-US', options);
      }
    }
  };
  </script>
  
  <style scoped>
  .node-status-section {
    font-family: 'Inter', 'Arial', sans-serif;
  }
  
  .node-status-component {
    font-family: 'Inter', 'Arial', sans-serif;
  }
  
  .status-container {
    background-color: #0d1117;
    border-radius: 12px;
    padding: 24px;
    color: white;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .status-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .project-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .project-name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #efdf8a;
  }
  
  .status-time {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .networks-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .network-section {
    background-color: rgba(22, 27, 34, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 10px;
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .network-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #efdf8a;
  }
  
  .network-nodes {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .node-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    background-color: rgba(13, 17, 23, 0.7);
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .node-item:hover {
    background-color: rgba(20, 25, 35, 0.7);
  }
  
  .node-status {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f14668;
  }
  
  .node-status.active .status-dot {
    background-color: #48c78e;
    box-shadow: 0 0 10px rgba(72, 199, 142, 0.5);
  }
  
  .node-name {
    font-size: 15px;
    font-weight: 500;
  }
  
  .status-indicator {
    min-width: 120px;
  }
  
  .pulse-container {
    display: flex;
    align-items: flex-end;
    height: 20px;
    gap: 3px;
  }
  
  .pulse-bar {
    width: 4px;
    height: 12px;
    background-color: #48c78e;
    border-radius: 2px;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  .pulse-bar:nth-child(1) { animation-delay: 0.0s; height: 10px; }
  .pulse-bar:nth-child(2) { animation-delay: 0.1s; height: 15px; }
  .pulse-bar:nth-child(3) { animation-delay: 0.2s; height: 8px; }
  .pulse-bar:nth-child(4) { animation-delay: 0.3s; height: 18px; }
  .pulse-bar:nth-child(5) { animation-delay: 0.4s; height: 12px; }
  .pulse-bar:nth-child(6) { animation-delay: 0.5s; height: 10px; }
  .pulse-bar:nth-child(7) { animation-delay: 0.6s; height: 15px; }
  .pulse-bar:nth-child(8) { animation-delay: 0.7s; height: 8px; }
  
  @keyframes pulse {
    0%, 100% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(1.5);
    }
  }
  
  .inactive-text {
    color: #f14668;
    font-size: 14px;
    text-align: right;
  }
  
  @media (max-width: 600px) {
    .status-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    
    .node-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    
    .status-indicator {
      align-self: flex-end;
    }
  }
  </style>