<template>
  <HeaderMain />
  <NetworkMain />
  <SosialMain />
  <PatnerMain />
</template>

<script>
import HeaderMain from "../components/HeaderMain.vue";
import NetworkMain from "../components/NetworkMain.vue";
import PatnerMain from "../components/PatnerMain.vue";
import SosialMain from "../components/SosialMain.vue";

export default {
  components: {
    HeaderMain,
    NetworkMain,
    PatnerMain,
    SosialMain,
  }
};
</script>
