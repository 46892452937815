// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Testnet from "../views/Testnet.vue";
import ProjectDetail from "../views/testnets/ProjectDetail.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Status from "../views/Status.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/testnet",
    name: "Testnet",
    component: Testnet
  },
  {
    path: "/testnet/:slug",
    name: "ProjectDetail",
    component: ProjectDetail,
    props: true
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/status",
    name: "Status",
    component: Status
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
});

export default router;