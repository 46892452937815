<!-- src/views/testnets/ProjectDetail.vue -->
<template>
  <section class="py-16 md:py-24 mt-16 md:mt-20 px-6 max-w-5xl mx-auto">
    <!-- Breadcrumb -->
    <nav class="text-white/70 text-sm mb-4">
      <router-link to="/" class="hover:text-blue-400 transition">Home</router-link> › 
      <router-link to="/testnet" class="hover:text-blue-400 transition">Testnet</router-link> › 
      <span class="text-white font-semibold">{{ projectData.name }}</span>
    </nav>

    <!-- Card Utama -->
    <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 text-white">
      <!-- Logo & Nama -->
      <div class="flex flex-col items-center">
        <div class="w-20 h-20 rounded-xl overflow-hidden flex items-center justify-center bg-white/10 p-2 mb-4">
          <img :src="projectData.logoUrl" :alt="projectData.name + ' Logo'" class="w-16 h-16" />
        </div>
        <h1 class="text-3xl font-bold mb-2">{{ projectData.name.toUpperCase() }}</h1>

        <!-- Social Media Icons - Menggunakan format asli -->
        <div class="flex items-center justify-center space-x-4 mt-4">
          <a :href="projectData.socialLinks.website.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
            </svg>
          </a>
          <a :href="projectData.socialLinks.github.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.342-3.369-1.342-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.202 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.933.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.416 22 12c0-5.523-4.477-10-10-10z"/>
            </svg>
          </a>
          <a :href="projectData.socialLinks.twitter.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>
          <a :href="projectData.socialLinks.telegram.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M9.78 18.65L10.06 14.42L17.74 7.5C18.08 7.19 17.67 7.04 17.22 7.31L7.74 13.3L3.64 12C2.76 11.75 2.75 11.14 3.84 10.7L19.81 4.54C20.54 4.21 21.24 4.72 20.96 5.84L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.78 18.65Z"/>
            </svg>
          </a>
          <a :href="projectData.socialLinks.discord.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 00-.079.036c-.21.385-.39.779-.53 1.162-1.796-.27-3.593-.27-5.325 0-.14-.38-.32-.777-.53-1.162a.077.077 0 00-.079-.036c-1.714.29-3.354.8-4.885 1.491a.07.07 0 00-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 00.03.057 20.03 20.03 0 006.031 3.056.077.077 0 00.084-.028c.462-.63.874-1.295 1.226-1.995a.076.076 0 00-.041-.106 13.209 13.209 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.372.292a.077.077 0 01-.006.127 12.412 12.412 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.963 19.963 0 006.032-3.055.077.077 0 00.032-.057c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.029zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
            </svg>
          </a>
          <a :href="projectData.socialLinks.docs.url" target="_blank" 
            class="text-[#efdf8a] hover:text-white transition-colors p-2">
            <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
              <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM13 9V3.5L18.5 9H13Z"/>
            </svg>
          </a>
        </div>

        <!-- Status -->
        <span class="mt-4 inline-block px-4 py-1.5 bg-[#efdf8a] text-gray-900 rounded-full text-sm font-medium">
          {{ projectData.status }}
        </span>
      </div>

      <!-- Deskripsi -->
      <p class="mt-8 leading-relaxed text-white/80" v-html="projectData.description"></p>
    </div>

    <!-- Fundraising dan System Requirements -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
      <!-- Fundraising -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 text-white">
        <h3 class="text-lg font-bold mb-4 flex items-center">
          <svg class="w-5 h-5 mr-2 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z" />
          </svg>
          Fundraising <span class="text-[#efdf8a] ml-1">{{ projectData.fundraising.amount }}</span>
        </h3>
        <div class="flex flex-wrap items-center gap-3">
          <a v-for="token in projectData.fundraising.tokens" :key="token.name" :href="token.link" target="_blank" class="transition-transform hover:scale-110">
            <img :src="token.logo" :alt="token.name" class="w-10 h-10 rounded-full border border-white/10">
          </a>
        </div>
      </div>

      <!-- System Requirements -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 text-white">
        <h3 class="text-lg font-bold mb-4 flex items-center">
          <svg class="w-5 h-5 mr-2 text-[#9ccdee]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M4 6h16v10H4V6zm16-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h4v2H8v2h8v-2h-4v-2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z" />
          </svg>
          System Requirements
        </h3>
        <ul class="text-white/70 space-y-2.5">
          <li v-for="(value, key) in projectData.systemRequirements" :key="key" class="flex justify-between items-center">
            <span class="text-white/80">{{ key }}:</span>
            <span class="font-medium text-white px-3 py-1 bg-white/5 rounded-lg">{{ value }}</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Rewards -->
    <div class="mt-8 backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 text-white">
      <h3 class="text-lg font-bold mb-4 flex items-center">
        <svg class="w-5 h-5 mr-2 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v6h-2zm0 8h2v2h-2z" />
        </svg>
        Rewards Information
      </h3>
      <p class="text-white/80" v-html="projectData.rewards"></p>
    </div>

    <!-- Tab Navigation (untuk proyek dengan tab) -->
    <div v-if="hasTabs" class="mt-8 backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 text-white">
      <div class="tab-container">
        <div class="tab-nav mb-6 border-b border-white/10">
          <div class="flex overflow-x-auto">
            <button 
              v-for="tab in tabs" 
              :key="tab.id"
              @click="setActiveTab(tab.id)"
              class="px-4 py-3 text-white/70 font-medium transition-all whitespace-nowrap"
              :class="activeTab === tab.id ? 'border-b-2 border-[#efdf8a] text-[#efdf8a]' : 'border-b-2 border-transparent hover:text-white'"
            >
              {{ tab.name }}
            </button>
          </div>
        </div>
        
        <div class="tab-content">
          <div 
            v-for="tab in tabs" 
            :key="tab.id"
            :class="{ 'block': activeTab === tab.id, 'hidden': activeTab !== tab.id }"
            v-html="renderTabContent(tab.id)"
          ></div>
        </div>
      </div>
    </div>
    
    <!-- Markdown Content Section (untuk proyek tanpa tab) -->
    <div v-else class="mt-8 backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 text-white">
      <div ref="markdownContainer" class="markdown-content dark-theme" v-html="renderedMarkdown"></div>
    </div>
  </section>
</template>

<script>
import MarkdownIt from 'markdown-it';
import { projectsData } from '../../data/projectsData'; // Pastikan path ke projectsData.js

export default {
  props: {
    slug: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      projectData: null,
      renderedMarkdown: '',
      activeTab: '', // Tab aktif saat ini
      tabs: [], // Array untuk menyimpan informasi tab
      tabSections: {}, // Menyimpan konten untuk setiap tab
      hasTabs: false // Flag untuk menunjukkan apakah markdown memiliki tab
    };
  },
  created() {
    // Dapatkan slug dari props atau dari route params
    const projectSlug = this.slug || this.$route.params.slug;
    
    // Cari data proyek berdasarkan slug
    this.projectData = projectsData.find(project => project.slug === projectSlug);
    
    // Redirect ke halaman testnet jika proyek tidak ditemukan
    if (!this.projectData) {
      this.$router.push('/testnet');
      return;
    }
    
    // Deteksi dan atur tab jika ada
    this.detectTabs();
  },
  mounted() {
    // Render konten yang sesuai
    if (this.hasTabs) {
      // Konten tab sudah dirender oleh v-html dan renderTabContent
    } else {
      this.renderMarkdown();
    }
    
    // Setelah DOM diperbarui, tambahkan tombol copy ke blok kode
    this.$nextTick(() => {
      this.addCopyButtonsToCodeBlocks();
    });
  },
  updated() {
    // Tambahkan tombol copy ke blok kode setelah DOM diperbarui
    this.$nextTick(() => {
      this.addCopyButtonsToCodeBlocks();
    });
  },
  methods: {
    // Deteksi apakah markdown berisi tag untuk tab
    detectTabs() {
      if (!this.projectData || !this.projectData.markdown) return;
      
      // Cek apakah format tab ditemukan di markdown
      if (this.projectData.markdown.includes('<div class="tab-container">') || 
          this.projectData.markdown.includes('<div id="installation" class="tab-content">')) {
        // Cari semua tab dalam markdown
        const tabRegex = /<div id="([^"]+)" class="tab-content[^>]*>([\s\S]*?)(?=<div id="|<\/div>\s*<\/div>)/g;
        const matches = [...this.projectData.markdown.matchAll(tabRegex)];
        
        if (matches.length > 0) {
          // Ekstrak informasi tab
          this.tabs = matches.map(match => {
            const id = match[1];
            const content = match[2];
            // Simpan konten tab untuk digunakan nanti
            this.tabSections[id] = content;
            return {
              id: id,
              name: this.getTabName(id)
            };
          });
          
          // Set tab pertama sebagai aktif
          if (this.tabs.length > 0) {
            this.activeTab = this.tabs[0].id;
          }
          
          this.hasTabs = true;
        }
      }
    },
    
    // Ubah ID tab menjadi nama yang ditampilkan
    getTabName(id) {
      const nameMap = {
        'installation': 'Installation',
        'validator': 'Validator Setup',
        'node': 'Node Management',
        'claim': 'Claim & Verify',
        // Tambahkan lainnya sesuai kebutuhan
      };
      return nameMap[id] || id.charAt(0).toUpperCase() + id.slice(1);
    },
    
    // Mengatur tab aktif
    setActiveTab(tabId) {
      this.activeTab = tabId;
      
      // Re-add copy buttons setelah tab diubah
      this.$nextTick(() => {
        this.addCopyButtonsToCodeBlocks();
      });
    },
    
    // Render konten tab dengan format kode yang dipertahankan
    renderTabContent(tabId) {
      const md = new MarkdownIt({
        html: true,
        breaks: true,
        linkify: true,
        // Konfigurasi highlight penting untuk menjaga format kode asli
        highlight: function(str, lang) {
          // Explicitly preserve newlines and whitespace formatting
          return '<pre class="code-block-original"><code class="' + (lang ? 'language-' + lang : '') + '">' + 
                 str + 
                 '</code></pre>';
        }
      });
      
      if (this.tabSections[tabId]) {
        return md.render(this.tabSections[tabId]);
      }
      return '';
    },
    
    // Render markdown normal dengan format kode yang dipertahankan
    renderMarkdown() {
      const md = new MarkdownIt({
        html: true,
        breaks: true,
        linkify: true,
        // Konfigurasi highlight penting untuk menjaga format kode asli
        highlight: function(str, lang) {
          // Explicitly preserve newlines and whitespace formatting
          return '<pre class="code-block-original"><code class="' + (lang ? 'language-' + lang : '') + '">' + 
                 str + 
                 '</code></pre>';
        }
      });
      
      if (this.projectData && this.projectData.markdown) {
        this.renderedMarkdown = md.render(this.projectData.markdown);
      }
    },
    
    // Enhanced function for adding copy buttons to code blocks
    addCopyButtonsToCodeBlocks() {
      // Select the appropriate container based on whether tabs are used or not
      const containers = this.hasTabs 
        ? document.querySelectorAll('.tab-content > div')
        : document.querySelectorAll('.markdown-content');
        
      if (!containers.length) return;
      
      containers.forEach(container => {
        if (!container) return;
        
        const codeBlocks = container.querySelectorAll('pre code');
        
        codeBlocks.forEach(codeBlock => {
          // Check if this code block already has a copy button (to avoid duplicates)
          if (codeBlock.parentElement.parentElement.classList.contains('code-block-wrapper')) {
            return;
          }
          
          // Get the parent 'pre' element
          const preBlock = codeBlock.parentElement;
          
          // Create wrapper div for layout
          const wrapper = document.createElement('div');
          wrapper.className = 'code-block-wrapper relative rounded-xl border border-white/20 bg-black/30 shadow-md mb-6 overflow-hidden';
          
          // Create header for code block
          const header = document.createElement('div');
          header.className = 'flex justify-between items-center py-2 px-4 bg-black/40 border-b border-white/10';
          
          // Get language for code block if specified (default to "bash")
          const langClass = codeBlock.className;
          let language = "bash";
          if (langClass) {
            const langMatch = langClass.match(/language-(\w+)/);
            if (langMatch && langMatch[1]) {
              language = langMatch[1];
            }
          }
          header.innerHTML = `<span class="text-xs text-white/60 font-mono language-indicator">${language}</span>`;
          
          // Create div for code content
          const codeContent = document.createElement('div');
          codeContent.className = 'code-content overflow-x-auto font-mono text-sm p-4 w-full';
          
          // Get the original text to be copied later - PRESERVE ORIGINAL FORMAT!
          const originalText = codeBlock.textContent;
          
          // Automatically detect various types of commands
          const commandType = this.detectCommandType(originalText);
          
          if (commandType.isValidatorCommand) {
            // Special format for validator commands
            this.formatValidatorCommand(originalText, codeContent);
          } else if (commandType.isServiceFile) {
            // Format for service files
            this.formatMultilineCommand(originalText, codeContent);
          } else if (commandType.isShellCommand) {
            // Format for regular shell commands
            this.formatMultilineCommand(originalText, codeContent);
          } else {
            // Default formatting for other code
            this.formatGenericCode(originalText, codeContent);
          }
          
          // Create copy button
          const copyBtn = document.createElement('div');
          copyBtn.className = 'copy-btn absolute top-2 right-2 flex items-center gap-1 text-white/60 hover:text-[#efdf8a] cursor-pointer transition-colors px-2 py-1 rounded';
          copyBtn.innerHTML = `
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
              <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
            </svg>
            <span class="text-xs">Copy</span>
          `;
          
          // Add hidden element to store the original text
          const hiddenTextarea = document.createElement('textarea');
          hiddenTextarea.style.position = 'absolute';
          hiddenTextarea.style.left = '-9999px';
          hiddenTextarea.style.top = '0';
          hiddenTextarea.value = originalText; // Store original text with formatting
          wrapper.appendChild(hiddenTextarea);
          
          // Event listener that copies the original format
          copyBtn.addEventListener('click', () => {
            try {
              // Use the original text from the hidden textarea
              navigator.clipboard.writeText(hiddenTextarea.value)
                .then(() => {
                  // Success feedback
                  copyBtn.innerHTML = `
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs">Copied!</span>
                  `;
                  
                  // Reset after 2 seconds
                  setTimeout(() => {
                    copyBtn.innerHTML = `
                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                        <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                      <span class="text-xs">Copy</span>
                    `;
                  }, 2000);
                })
                .catch(() => {
                  copyBtn.innerHTML = `
                    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs">Failed!</span>
                  `;
                  
                  // Reset after 2 seconds
                  setTimeout(() => {
                    copyBtn.innerHTML = `
                      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                        <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>
                      <span class="text-xs">Copy</span>
                    `;
                  }, 2000);
                });
            } catch (err) {
              // Fallback for browsers that don't support clipboard API
              const tempTextArea = document.createElement('textarea');
              tempTextArea.value = hiddenTextarea.value;
              document.body.appendChild(tempTextArea);
              tempTextArea.select();
              
              try {
                document.execCommand('copy');
                copyBtn.innerHTML = `
                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-xs">Copied!</span>
                `;
              } catch (err) {
                copyBtn.innerHTML = `
                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-xs">Failed!</span>
                `;
              }
              
              document.body.removeChild(tempTextArea);
              
              // Reset after 2 seconds
              setTimeout(() => {
                copyBtn.innerHTML = `
                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                  </svg>
                  <span class="text-xs">Copy</span>
                `;
              }, 2000);
            }
          });
          
          // Combine components
          header.appendChild(copyBtn);
          wrapper.appendChild(header);
          wrapper.appendChild(codeContent);
          
          // Replace the original pre element with the enhanced version
          preBlock.parentNode.replaceChild(wrapper, preBlock);
        });
      });
    },

    // Deteksi otomatis jenis perintah
    detectCommandType(text) {
      // Regexp untuk mendeteksi tx staking create-validator atau edit-validator
      const validatorCommandRegex = /\s+tx\s+staking\s+(create|edit)-validator\s/;
      
      // Specifically detect "<<EOF" pattern with systemd sections
      const hasEOFPattern = text.includes('<<EOF');
      const hasSystemdSections = text.includes('[Unit]') || 
                                text.includes('[Service]') || 
                                text.includes('[Install]');
      
      // Improved service file detection
      const isServiceFile = (
        // Check for tee command with EOF
        (text.includes('tee') && hasEOFPattern && hasSystemdSections) ||
        // Direct EOF with systemd sections
        (hasEOFPattern && hasSystemdSections) ||
        // Standard systemd sections
        (text.includes('[Unit]') && text.includes('[Service]') && text.includes('[Install]')) ||
        // Any command with systemd service path
        (text.includes('/etc/systemd/system/') && text.includes('service'))
      );
      
      // Deteksi berbagai perintah shell
      const isShellCommand = text.includes('sudo ') || 
        text.includes('curl ') || 
        text.includes('wget ') || 
        text.includes('systemctl ') || 
        text.includes('apt ') || 
        text.includes('sed -i') || 
        text.trim().startsWith('cd ') ||
        text.includes('/etc/systemd/') ||
        text.includes('tendermint show-validator') ||
        text.includes(' keys ') ||
        text.includes(' q ') || 
        text.includes(' query ');
      
      // Deteksi perintah validator (dari berbagai proyek)
      // Ini akan mendeteksi pola 'xxxx tx staking create-validator' 
      // dimana xxxx bisa symphonyd, atomoned, intentod, dll.
      const isValidatorCommand = validatorCommandRegex.test(text);
      
      return {
        isValidatorCommand,
        isServiceFile,
        isShellCommand
      };
    },
    
    // Format perintah validator menjadi command dan params
    formatValidatorCommand(text, container) {
      // Reset flag -y
      let yFlag = null;
      
      // Parse baris
      const lines = text.split('\n');
      let mainCommand = '';
      const params = [];
      
      // Proses baris pertama sebagai main command
      if (lines[0].includes(' -y')) {
        mainCommand = lines[0].replace(' -y', '').trim();
        yFlag = '-y';
      } else {
        mainCommand = lines[0].trim();
      }
      
      // Proses parameter-parameter
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        
        if (line === '-y') {
          yFlag = '-y';
        } else if (line.endsWith(' -y')) {
          params.push(line.replace(' -y', ''));
          yFlag = '-y';
        } else if (line) {
          params.push(line);
        }
      }
      
      // Buat elemen untuk perintah utama
      const mainCommandEl = document.createElement('div');
      mainCommandEl.className = 'code-line main-command';
      mainCommandEl.textContent = mainCommand;
      container.appendChild(mainCommandEl);
      
      // Buat elemen untuk setiap parameter
      params.forEach(param => {
        const paramEl = document.createElement('div');
        paramEl.className = 'code-line param-line';
        paramEl.textContent = param;
        container.appendChild(paramEl);
      });
      
      // Tambahkan -y di akhir jika ada
      if (yFlag) {
        const yFlagEl = document.createElement('div');
        yFlagEl.className = 'code-line param-line';
        yFlagEl.textContent = yFlag;
        container.appendChild(yFlagEl);
      }
    },
    
    // Format perintah multi-baris seperti service file atau shell commands
    // Format perintah multi-baris seperti service file atau shell commands
formatMultilineCommand(text, container) {
  const lines = text.split('\n');
  
  // Check if this is a service file with systemd sections
  const isServiceFile = (
    text.includes('[Unit]') || 
    text.includes('[Service]') || 
    text.includes('[Install]')
  );
  
  lines.forEach(line => {
    // Check for EOF marker start
    if (line.includes('<<EOF')) {
      // Create the command line that contains tee and EOF
      const cmdLine = document.createElement('div');
      cmdLine.className = 'code-line command-line';
      cmdLine.textContent = line;
      container.appendChild(cmdLine);
      return;
    }
    
    // Check for EOF marker end
    if (line.trim() === 'EOF') {
      const eofLine = document.createElement('div');
      eofLine.className = 'code-line eof-marker';
      eofLine.textContent = line;
      container.appendChild(eofLine);
      return;
    }
    
    // Handle empty lines
    if (line.trim() === '') {
      container.appendChild(document.createElement('br'));
      return;
    }
    
    // Format service file sections specially
    if (isServiceFile && /^\[.+\]$/.test(line.trim())) {
      // This is a section header
      const sectionDiv = document.createElement('div');
      sectionDiv.className = 'code-line section-header';
      sectionDiv.textContent = line;
      container.appendChild(sectionDiv);
    } else if (isServiceFile && line.includes('=')) {
      // This is a key-value pair in the service file
      const configDiv = document.createElement('div');
      configDiv.className = 'code-line config-line';
      
      // Split at the first equals sign
      const parts = line.split(/=(.+)/);
      
      if (parts.length >= 2) {
        const keySpan = document.createElement('span');
        keySpan.className = 'config-key';
        keySpan.textContent = parts[0] + '=';
        
        const valueSpan = document.createElement('span');
        valueSpan.className = 'config-value';
        valueSpan.textContent = parts[1];
        
        configDiv.appendChild(keySpan);
        configDiv.appendChild(valueSpan);
      } else {
        configDiv.textContent = line;
      }
      
      container.appendChild(configDiv);
    } else {
      // Regular line
      const lineDiv = document.createElement('div');
      lineDiv.className = 'code-line';
      lineDiv.style.display = 'block';
      lineDiv.textContent = line;
      container.appendChild(lineDiv);
    }
  });
},
    
    // Format untuk kode generik
    formatGenericCode(text, container) {
      const lines = text.split('\n');
      
      lines.forEach(line => {
        if (line.trim() === '') {
          // Tambahkan baris kosong
          container.appendChild(document.createElement('br'));
        } else {
          // Buat elemen div baru untuk setiap baris kode
          const lineDiv = document.createElement('div');
          lineDiv.className = 'code-line';
          lineDiv.style.display = 'block';
          lineDiv.textContent = line;
          container.appendChild(lineDiv);
        }
      });
    }
  }
};
</script>

<style>
/* Styling untuk markdown content - dark theme */
.markdown-content.dark-theme h1,
.tab-content h1 {
  font-size: 1.875rem; /* text-3xl */
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.markdown-content.dark-theme h2,
.tab-content h2 {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
}

.markdown-content.dark-theme h3,
.tab-content h3 {
  font-size: 1.25rem; /* text-xl */
  font-weight: 600;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  color: white;
}

.markdown-content.dark-theme h4,
.tab-content h4 {
  font-size: 1.125rem; /* text-lg */
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.markdown-content.dark-theme p,
.tab-content p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.8);
}

.markdown-content.dark-theme ul,
.tab-content ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
}

.markdown-content.dark-theme ol,
.tab-content ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
}

.markdown-content.dark-theme li,
.tab-content li {
  margin-bottom: 0.5rem;
}

.markdown-content.dark-theme strong,
.tab-content strong {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.95);
}

.markdown-content.dark-theme a,
.tab-content a {
  color: #efdf8a;
  text-decoration: none;
  transition: all 0.2s;
}

.markdown-content.dark-theme a:hover,
.tab-content a:hover {
  color: white;
  text-decoration: underline;
}

.markdown-content.dark-theme blockquote,
.tab-content blockquote {
  border-left: 4px solid rgba(239, 223, 138, 0.5);
  padding-left: 1rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.markdown-content.dark-theme table,
.tab-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markdown-content.dark-theme th,
.tab-content th {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  font-weight: 600;
  text-align: left;
}

.markdown-content.dark-theme td,
.tab-content td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
}

/* Style khusus untuk kotak kode */
.markdown-content.dark-theme pre,
.tab-content pre,
.code-block-original {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: transparent;
  white-space: pre !important;
}

.code-block-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease;
}

.code-block-wrapper:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
}

/* Style untuk mencegah word-wrap pada kode */
.code-content {
  white-space: pre !important; /* PENTING - Pertahankan format asli */
  overflow-x: auto;
  max-width: 100%;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", monospace;
}

.code-content code {
  white-space: pre !important;
  background: transparent;
  color: rgba(255, 255, 255, 0.95) !important;
}

/* Style for inline code */
.markdown-content.dark-theme :not(pre) > code,
.tab-content :not(pre) > code {
  background: rgba(255, 255, 255, 0.1);
  color: #efdf8a;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
  font-size: 0.9em;
}

/* Animation for tab transitions */
.tab-container {
  position: relative;
}

.tab-nav {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.tab-nav::-webkit-scrollbar {
  height: 4px;
}

.tab-nav::-webkit-scrollbar-track {
  background: transparent;
}

.tab-nav::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

/* Tab content animation */
.tab-content div {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Style untuk baris kode */
.code-line {
  display: block;
  white-space: pre !important; /* PENTING - Pertahankan format asli */
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.95) !important;
}

/* Pastikan blok kode tidak memotong baris panjang dan menampilkan scrollbar horizontal saat diperlukan */
.code-content {
  overflow-x: auto;
  white-space: pre-wrap;
  word-break: keep-all;
}

/* Style khusus untuk perintah CLI */
.main-command {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.95);
}

.param-line {
  display: block !important;
  padding-left: 2em !important;
  text-indent: 0 !important;
  margin-bottom: 0.25rem !important;
  color: rgba(255, 255, 255, 0.95) !important;
}

/* Enhanced button hover effect */
.copy-btn {
  opacity: 0.7;
  transition: opacity 0.2s ease, transform 0.2s ease;
  z-index: 10; /* Ensure button is above code content */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  border-radius: 4px;
  padding: 4px 8px !important;
}

.code-block-wrapper:hover .copy-btn {
  opacity: 1;
}

.copy-btn:hover {
  transform: scale(1.05);
}

/* Language tab styling */
.language-indicator {
  font-family: 'Courier New', monospace;
  letter-spacing: 0.5px;
}

/* Special styles for EOF heredoc service files */
.command-line {
  color: #9ccdee !important;
  font-weight: 600;
  margin-bottom: 8px;
}

.eof-marker {
  color: #9ccdee !important;
  font-weight: 600;
  margin-top: 8px;
}

/* Improved section header for service files */
.section-header {
  font-weight: bold !important;
  color: #efdf8a !important;
  margin-top: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  padding-left: 0.5rem !important;
}

/* Better key-value formatting */
.config-line {
  padding-left: 1.5rem !important;
  line-height: 1.6 !important;
}

.config-key {
  color: rgba(255, 255, 255, 0.85) !important;
  font-weight: 500;
}

.config-value {
  color: rgba(156, 205, 238, 0.95) !important;
}

/* Ensure code blocks don't overflow their container */
.code-block-wrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

/* Add a little more space around code blocks */
pre.code-block-original {
  padding: 1rem;
}
</style>