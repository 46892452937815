<template>
    <section class="social-media-section relative py-16 md:py-24">
      <!-- Background decorative elements -->
      <div class="absolute inset-0 -z-10">
        <div class="absolute left-10 top-1/3 w-48 h-48 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
        <div class="absolute right-10 bottom-1/3 w-48 h-48 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
      </div>
      
      <div class="container mx-auto px-6 md:px-8">
        <!-- Section header -->
        <div class="text-center mb-12">
          <h2 class="text-3xl md:text-4xl font-bold mb-4 text-white">
            Connect With <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581]">Our Community</span>
          </h2>
          <p class="text-white/70 text-lg max-w-2xl mx-auto">
            Join our growing community across various social platforms to stay updated and engage with fellow node runners
          </p>
        </div>
        
        <!-- Social Media Cards -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <!-- Telegram Card -->
          <a href="https://t.me/bangpateng_airdrop" target="_blank" rel="noopener noreferrer" class="social-card telegram-card flex flex-col items-center backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#229ED9]/10 group">
            <div class="w-16 h-16 rounded-full flex items-center justify-center bg-[#229ED9]/10 mb-6 group-hover:scale-110 transition-transform">
              <svg class="w-8 h-8 text-[#229ED9]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.3-.6.3l.213-3.034 5.52-4.982c.24-.213-.054-.334-.373-.121l-6.826 4.297-2.917-.967c-.633-.197-.643-.638.134-.942l11.574-4.458c.548-.197 1.025.127.775.934z"/>
              </svg>
            </div>
            <h3 class="text-xl font-bold text-white mb-2">Telegram</h3>
            <p class="text-white/70 text-center mb-6">Join our Telegram channel for instant updates and community discussions</p>
            <div class="mt-auto flex items-center text-[#229ED9] font-medium">
              <span class="mr-2">Join Channel</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transform group-hover:translate-x-1 transition-transform" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="absolute top-4 right-4 bg-[#229ED9]/10 text-[#229ED9] rounded-full px-2.5 py-0.5 text-xs font-medium">
              {{ socialStats.telegram }}+ Members
            </div>
          </a>
          
          <!-- Twitter/X Card -->
          <a href="https://twitter.com/bangpateng_" target="_blank" rel="noopener noreferrer" class="social-card twitter-card flex flex-col items-center backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#1DA1F2]/10 group">
            <div class="w-16 h-16 rounded-full flex items-center justify-center bg-[#1DA1F2]/10 mb-6 group-hover:scale-110 transition-transform">
              <svg class="w-8 h-8 text-[#1DA1F2]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
              </svg>
            </div>
            <h3 class="text-xl font-bold text-white mb-2">Twitter</h3>
            <p class="text-white/70 text-center mb-6">Follow us on Twitter for the latest news, updates and project announcements</p>
            <div class="mt-auto flex items-center text-[#1DA1F2] font-medium">
              <span class="mr-2">Follow Us</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transform group-hover:translate-x-1 transition-transform" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="absolute top-4 right-4 bg-[#1DA1F2]/10 text-[#1DA1F2] rounded-full px-2.5 py-0.5 text-xs font-medium">
              {{ socialStats.twitter }}+ Followers
            </div>
          </a>
          
          <!-- YouTube Card -->
          <a href="https://youtube.com/c/bangpateng" target="_blank" rel="noopener noreferrer" class="social-card youtube-card flex flex-col items-center backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#FF0000]/10 group">
            <div class="w-16 h-16 rounded-full flex items-center justify-center bg-[#FF0000]/10 mb-6 group-hover:scale-110 transition-transform">
              <svg class="w-8 h-8 text-[#FF0000]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
              </svg>
            </div>
            <h3 class="text-xl font-bold text-white mb-2">YouTube</h3>
            <p class="text-white/70 text-center mb-6">Check out our tutorials, guides, and explainer videos on blockchain projects</p>
            <div class="mt-auto flex items-center text-[#FF0000] font-medium">
              <span class="mr-2">Subscribe</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transform group-hover:translate-x-1 transition-transform" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="absolute top-4 right-4 bg-[#FF0000]/10 text-[#FF0000] rounded-full px-2.5 py-0.5 text-xs font-medium">
              {{ socialStats.youtube }}+ Subscribers
            </div>
          </a>
        </div>
        
        <!-- Additional Social Links -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-6 md:p-8">
          <h3 class="text-xl font-bold text-center text-white mb-8">More Ways to Connect</h3>
          
          <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 md:gap-6">
            <!-- Discord -->
            <a href="https://discord.gg/mukidihajar" target="_blank" rel="noopener noreferrer" class="social-link-card flex flex-col items-center p-4 backdrop-blur-sm bg-white/5 rounded-xl transition-all hover:bg-white/10 group">
              <div class="w-12 h-12 rounded-full flex items-center justify-center bg-[#5865F2]/10 mb-3 group-hover:scale-110 transition-transform">
                <svg class="w-6 h-6 text-[#5865F2]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/>
                </svg>
              </div>
              <span class="text-white/80 text-sm">Discord</span>
            </a>
            
            <!-- Medium -->
            <a href="https://medium.com/@bangpateng" target="_blank" rel="noopener noreferrer" class="social-link-card flex flex-col items-center p-4 backdrop-blur-sm bg-white/5 rounded-xl transition-all hover:bg-white/10 group">
              <div class="w-12 h-12 rounded-full flex items-center justify-center bg-[#12100E]/10 mb-3 group-hover:scale-110 transition-transform">
                <svg class="w-6 h-6 text-[#12100E]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M13.54 12a6.8 6.8 0 01-6.77 6.82A6.8 6.8 0 010 12a6.8 6.8 0 016.77-6.82A6.8 6.8 0 0113.54 12zM20.96 12c0 3.54-1.51 6.42-3.38 6.42-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42 3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z"/>
                </svg>
              </div>
              <span class="text-white/80 text-sm">Medium</span>
            </a>
            
            <!-- GitHub -->
            <a href="https://github.com/bangpateng" target="_blank" rel="noopener noreferrer" class="social-link-card flex flex-col items-center p-4 backdrop-blur-sm bg-white/5 rounded-xl transition-all hover:bg-white/10 group">
              <div class="w-12 h-12 rounded-full flex items-center justify-center bg-[#181717]/10 mb-3 group-hover:scale-110 transition-transform">
                <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
                </svg>
              </div>
              <span class="text-white/80 text-sm">GitHub</span>
            </a>
            
            <!-- Reddit -->
            <a href="https://reddit.com/r/bangpateng" target="_blank" rel="noopener noreferrer" class="social-link-card flex flex-col items-center p-4 backdrop-blur-sm bg-white/5 rounded-xl transition-all hover:bg-white/10 group">
              <div class="w-12 h-12 rounded-full flex items-center justify-center bg-[#FF4500]/10 mb-3 group-hover:scale-110 transition-transform">
                <svg class="w-6 h-6 text-[#FF4500]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"/>
                </svg>
              </div>
              <span class="text-white/80 text-sm">Reddit</span>
            </a>
            
            <!-- Instagram -->
            <a href="https://instagram.com/bangpateng_" target="_blank" rel="noopener noreferrer" class="social-link-card flex flex-col items-center p-4 backdrop-blur-sm bg-white/5 rounded-xl transition-all hover:bg-white/10 group">
              <div class="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-br from-[#833AB4]/10 via-[#FD1D1D]/10 to-[#FCAF45]/10 mb-3 group-hover:scale-110 transition-transform">
                <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <defs>
                    <linearGradient id="instagramGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stop-color="#833AB4"/>
                      <stop offset="50%" stop-color="#FD1D1D"/>
                      <stop offset="100%" stop-color="#FCAF45"/>
                    </linearGradient>
                  </defs>
                  <path fill="url(#instagramGradient)" d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z"/>
                </svg>
              </div>
              <span class="text-white/80 text-sm">Instagram</span>
            </a>
          </div>
        </div>
        
        <!-- Community Highlights -->
        <!-- Community Highlights -->
<div class="mt-16 text-center">
  <a href="https://t.me/bangpateng_airdrop" target="_blank" rel="noopener noreferrer"
     class="inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all duration-300">
    Join Our Community
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
    </svg>
  </a>
</div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'SocialMediaSection',
    data() {
      return {
        socialStats: {
          telegram: '73.5K',
          twitter: '12K',
          youtube: '32.2K',
          discord: '9.1K',
          reddit: '3.4K'
        }
      }
    }
  }
  </script>
  
  <style scoped>
  /* Card hover effects */
  .social-card {
    transition: all 0.3s ease;
  }
  
  .social-card:hover {
    transform: translateY(-5px);
  }
  
  /* Additional social link hover effects */
  .social-link-card:hover span {
    color: white;
  }
  
  /* Gradient for Instagram (fallback for browsers that don't support SVG gradients) */
  .instagram-gradient {
    background: linear-gradient(to right bottom, #833AB4, #FD1D1D, #FCAF45);
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .social-media-section {
      padding-top: 8rem; /* Add space for fixed navbar on mobile */
    }
  }
  </style>