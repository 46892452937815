<template>
  <section class="trending-projects-section relative py-20 md:py-32 mt-16 md:mt-24">
    <!-- Subtle gradient background -->
    <div class="absolute inset-0 bg-gradient-to-br from-gray-900/50 to-black/80 -z-10"></div>
    
    <!-- Header -->
    <div class="container mx-auto px-4 mb-8">
      <div class="flex flex-wrap justify-between items-center gap-4">
        <h2 class="text-xl font-bold text-white">
          Testnets <span class="text-[#efdf8a]">•</span>
        </h2>
        
        <!-- Pill navigation with updated categories -->
        <div class="flex flex-wrap gap-2">
          <button 
            v-for="(tab, index) in filterTabs" 
            :key="index"
            @click="activeTab = tab.id"
            class="text-xs px-3 py-1 rounded-full transition-all"
            :class="activeTab === tab.id ? 
              'bg-[#efdf8a] text-black font-medium' : 
              'bg-white/5 text-white/70 hover:text-white'"
          >
            {{ tab.name }}
          </button>
        </div>
      </div>
    </div>
    
    <!-- Project grid -->
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3">
        <div v-for="(project, index) in filteredProjects" :key="index" 
             class="minimal-card bg-black/40 backdrop-blur-sm border border-white/5 rounded-lg p-3 hover:border-[#efdf8a]/30 transition-all">
          
          <!-- Logo and name -->
          <div class="flex items-center gap-2 mb-3">
            <img :src="project.logo" :alt="project.name" class="w-6 h-6 rounded-full">
            <h3 class="text-xs font-medium text-white truncate">{{ project.name }}</h3>
          </div>
          
          <!-- Status and network in a row -->
          <div class="flex justify-between items-center mb-3">
            <div class="flex items-center">
              <span class="w-1.5 h-1.5 rounded-full mr-1.5"
                :class="{
                  'bg-green-400': project.status === 'Active', 
                  'bg-blue-400': project.status === 'Upcoming',
                  'bg-gray-400': project.status === 'Ended'
                }"></span>
              <span class="text-[10px] text-white/60">{{ project.status }}</span>
            </div>
            
            <div class="flex items-center gap-1">
              <img :src="project.networkIcon" :alt="project.network" class="w-2.5 h-2.5">
              <span class="text-[10px] text-white/60">{{ project.network }}</span>
            </div>
          </div>
          
          <!-- Stats -->
          <div class="flex justify-between text-[10px] mb-3">
            <span class="text-[#efdf8a]">{{ project.rewards }}</span>
            <span class="text-white/60">{{ project.participants }} participants</span>
          </div>
          
          <!-- View button with yellow theme -->
          <router-link :to="'/testnet/' + project.slug" 
             class="block w-full text-center text-[10px] py-1.5 rounded-full text-black font-medium bg-[#efdf8a] hover:bg-[#ead581] transition-all">
            View Details
          </router-link>
        </div>
      </div>

      <!-- Minimal view all link with yellow theme -->
      <div class="mt-6 text-center">
        <router-link to="/testnet" class="text-xs text-[#efdf8a] hover:underline">
          View All Testnets →
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MinimalTestnetSection',
  data() {
    return {
      activeTab: 'all',
      filterTabs: [
        { id: 'all', name: 'All' },
        { id: 'active', name: 'Active' },
        { id: 'upcoming', name: 'Upcoming' },
        { id: 'ended', name: 'Ended' }
      ],
      projects: [
      {
          name: 'Gensyn',
          slug: 'gensyn',
          status: 'Active',
          network: 'EVM',
          networkIcon: new URL('@/assets/icons/gensyn.svg', import.meta.url).href,
          logo: new URL('@/assets/icons/gensyn.svg', import.meta.url).href,
          rewards: '$43M Raised',
          participants: '15,246'
        },
      {
          name: 'Succinct',
          slug: 'succinct',
          status: 'Active',
          network: 'EVM',
          networkIcon: new URL('@/assets/icons/succinct.svg', import.meta.url).href,
          logo: new URL('@/assets/icons/succinct.svg', import.meta.url).href,
          rewards: '$55M Raised',
          participants: '21,246'
        },
      {
          name: 'Ritual',
          slug: 'ritual',
          status: 'Active',
          network: 'EVM',
          networkIcon: new URL('@/assets/icons/ritual.svg', import.meta.url).href,
          logo: new URL('@/assets/icons/ritual.svg', import.meta.url).href,
          rewards: '$25M Raised',
          participants: '7,246'
        },
      {
          name: 'Soundness',
          slug: 'soundness',
          status: 'Active',
          network: 'SUI',
          networkIcon: new URL('@/assets/icons/soundness.svg', import.meta.url).href,
          logo: new URL('@/assets/icons/soundness.svg', import.meta.url).href,
          rewards: '$TBA',
          participants: '1,246'
        },
        {
          name: 'T3rn',
          slug: 't3rn',
          status: 'Active',
          network: 'EVM',
          networkIcon: new URL('@/assets/icons/t3rn.png', import.meta.url).href,
          logo: new URL('@/assets/icons/t3rn.png', import.meta.url).href,
          rewards: '$6.5M Raised',
          participants: '16,246'
        },
        {
          name: 'Arichain',
          slug: 'arichain',
          status: 'Upcoming',
          network: 'IBC',
          networkIcon: new URL('@/assets/icons/arichain.png', import.meta.url).href,
          logo: new URL('@/assets/icons/arichain.png', import.meta.url).href,
          rewards: '$TBA',
          participants: '2,246'
        },
        {
          name: 'Intento',
          slug: 'intento',
          status: 'Upcoming',
          network: 'IBC',
          networkIcon: new URL('@/assets/icons/intento.png', import.meta.url).href,
          logo: new URL('@/assets/icons/intento.png', import.meta.url).href,
          rewards: '$TBA',
          participants: '203'
        },
        {
          name: 'Dill',
          slug: 'dill',
          status: 'Active',
          network: 'IBC',
          networkIcon: new URL('@/assets/icons/dill.png', import.meta.url).href,
          logo: new URL('@/assets/icons/dill.png', import.meta.url).href,
          rewards: '$TBA',
          participants: '11,246'
        },
        {
          name: 'Symphony',
          slug: 'symphony',
          status: 'Active',
          network: 'IBC',
          networkIcon: new URL('@/assets/icons/symphony.png', import.meta.url).href,
          logo: new URL('@/assets/icons/symphony.png', import.meta.url).href,
          rewards: '$TBA',
          participants: '5,246'
        }
      ]
    };
  },
  computed: {
    filteredProjects() {
      if (this.activeTab === 'all') {
        return this.projects;
      } else if (this.activeTab === 'active') {
        return this.projects.filter(p => p.status === 'Active');
      } else if (this.activeTab === 'upcoming') {
        return this.projects.filter(p => p.status === 'Upcoming');
      } else if (this.activeTab === 'ended') {
        return this.projects.filter(p => p.status === 'Ended');
      }
      return this.projects;
    }
  }
};
</script>

<style scoped>
.minimal-card {
  transition: all 0.2s ease;
}

.minimal-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(239, 223, 138, 0.07);
}
</style>