<!-- src/views/Licensing.vue -->
<template>
  <div class="terms-service-container py-20 md:py-32 mt-16 md:mt-20 px-6 max-w-5xl mx-auto">
    <!-- Header -->
    <div class="text-center mb-16">
      <h1 class="text-4xl md:text-5xl font-bold text-white mb-4">Terms of Service</h1>
      <p class="text-white/70 text-lg">Last Updated: March 15, 2025</p>
    </div>

    <!-- Terms of Service Content -->
    <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 text-white">
      <!-- Introduction -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Introduction</h2>
        <p class="text-white/80 mb-4">
          Welcome to Bang Pateng. These Terms of Service ("Terms") govern your access to and use of our website, applications, and services (collectively, the "Services").
        </p>
        <p class="text-white/80">
          By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Services.
        </p>
      </section>

      <!-- Definitions -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Definitions</h2>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li><strong>"We", "Us", "Our"</strong> refers to BangPateng Testnet Explorer.</li>
          <li><strong>"User", "You", "Your"</strong> refers to the person accessing or using our Services.</li>
          <li><strong>"Content"</strong> refers to text, images, videos, code, and other material displayed on or through our Services.</li>
          <li><strong>"Testnet"</strong> refers to test blockchain networks that our Services provides information about.</li>
          <li><strong>"Node"</strong> refers to participants in the blockchain networks.</li>
        </ul>
      </section>

      <!-- Use of Services -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Use of Services</h2>
        <p class="text-white/80 mb-4">Our Services are provided for informational purposes only. By using our Services, you agree to:</p>
        
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Use our Services in compliance with all applicable laws and regulations.</li>
          <li>Not attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running our Services.</li>
          <li>Not use our Services for any illegal purpose or to conduct illegal activities.</li>
          <li>Not automate or programmatically scrape or access our Services without our express written permission.</li>
          <li>Not impersonate another person or misrepresent your affiliation with a person or entity.</li>
          <li>Not bypass measures we may use to prevent or restrict access to our Services.</li>
        </ul>
      </section>

      <!-- Account Registration -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Account Registration</h2>
        <p class="text-white/80 mb-4">
          Some features of our Services may require you to register for an account. When you register, you agree to:
        </p>
        
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Provide accurate, current, and complete information.</li>
          <li>Maintain and promptly update your account information.</li>
          <li>Keep your password secure and confidential.</li>
          <li>Be responsible for all activities that occur under your account.</li>
          <li>Notify us immediately of any unauthorized use of your account.</li>
        </ul>
        
        <p class="text-white/80 mt-4">
          We reserve the right to suspend or terminate your account if any information provided during registration or thereafter proves to be inaccurate, outdated, or incomplete.
        </p>
      </section>

      <!-- Intellectual Property -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Intellectual Property</h2>
        <p class="text-white/80 mb-4">
          The Services and its original content, features, and functionality are owned by BangPateng Testnet Explorer and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
        </p>
        
        <h3 class="text-xl font-semibold mb-2 text-white">Our Content</h3>
        <p class="text-white/80 mb-4">
          Our Content, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, is our property and is protected by copyright and other intellectual property laws.
        </p>
        
        <h3 class="text-xl font-semibold mb-2 text-white">Your Content</h3>
        <p class="text-white/80 mb-4">
          By submitting, posting, or displaying Content on or through our Services, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute such Content in any media.
        </p>
        
        <p class="text-white/80">
          You represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use your Content as described above.
        </p>
      </section>

      <!-- Testnet Participation -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Testnet Participation</h2>
        <p class="text-white/80 mb-4">
          Our Services provide information about various blockchain testnets. When participating in these testnets through our platform, you acknowledge and agree that:
        </p>
        
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Testnets are experimental networks and may be unstable or unreliable.</li>
          <li>We do not guarantee the functionality, security, or availability of any testnet.</li>
          <li>Testnet tokens have no real-world value and should not be purchased or sold.</li>
          <li>You are solely responsible for any actions taken while participating in testnets.</li>
          <li>Testnet participation may involve technical risks, including loss of data or digital assets.</li>
          <li>We may terminate, modify, or restrict access to any testnet at any time without notice.</li>
        </ul>
      </section>

      <!-- Disclaimers -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Disclaimers</h2>
        <p class="text-white/80 mb-4">
          OUR SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
        </p>
        
        <p class="text-white/80 mb-4">
          TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        </p>
        
        <p class="text-white/80">
          WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>
      </section>

      <!-- Limitation of Liability -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Limitation of Liability</h2>
        <p class="text-white/80 mb-4">
          IN NO EVENT SHALL WE, OUR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
        </p>
        
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES;</li>
          <li>ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES;</li>
          <li>ANY CONTENT OBTAINED FROM THE SERVICES; AND</li>
          <li>UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT.</li>
        </ul>
      </section>

      <!-- Indemnification -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Indemnification</h2>
        <p class="text-white/80">
          You agree to defend, indemnify, and hold harmless BangPateng Testnet Explorer, its officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:
        </p>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Your use of and access to the Services;</li>
          <li>Your violation of any term of these Terms;</li>
          <li>Your violation of any third-party right, including without limitation any copyright, property, or privacy right; or</li>
          <li>Any claim that your Content caused damage to a third party.</li>
        </ul>
      </section>

      <!-- Changes to Terms -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Changes to Terms</h2>
        <p class="text-white/80">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>
        <p class="text-white/80 mt-4">
          By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use our Services.
        </p>
      </section>

      <!-- Governing Law -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Governing Law</h2>
        <p class="text-white/80">
          These Terms shall be governed and construed in accordance with the laws, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
        </p>
      </section>

      <!-- Contact Information -->
      <section>
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Contact Us</h2>
        <p class="text-white/80 mb-4">
          If you have any questions about these Terms, please contact us:
        </p>
        <div class="backdrop-blur-sm bg-white/5 rounded-lg p-4 inline-block">
          <p class="text-white font-medium">Bang Pateng</p>
          <p class="text-white/80">Email: terms@bangpateng.com</p>
          <p class="text-white/80">Address: Crypto Valley, Blockchain Street 123</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  metaInfo: {
    title: 'Terms of Service - Bang Pateng'
  }
}
</script>

<style scoped>
/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .terms-service-container {
    padding-top: 6rem;
  }
}

/* Component-specific styles */
section h2 {
  position: relative;
  padding-bottom: 0.75rem;
}

section h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #efdf8a, rgba(239, 223, 138, 0.5));
  border-radius: 3px;
}
</style>