<template>
  <section class="about-service-section relative py-20 md:py-32">
    <!-- Decorative background elements -->
    <div class="absolute top-1/3 right-5 w-64 h-64 bg-[#efdf8a]/10 rounded-full blur-3xl -z-10"></div>
    <div class="absolute bottom-1/3 left-5 w-72 h-72 bg-[#9ccdee]/10 rounded-full blur-3xl -z-10"></div>
    
    <!-- Main content -->
    <div class="container mx-auto px-6 md:px-8">
      <!-- Section header -->
      <div class="text-center mb-16 max-w-3xl mx-auto">
        <h2 class="text-3xl md:text-4xl font-bold mb-6 text-white">
          Your <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581]">Ultimate Guide</span> to Blockchain Opportunities
        </h2>
        <p class="text-white/70 text-lg">
          We provide comprehensive resources and step-by-step tutorials for participating in blockchain testnet programs, running validator nodes, and claiming potential airdrops.
        </p>
      </div>
      
      <!-- Three columns section -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        <!-- Testnet Guide Column -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#efdf8a]/5">
          <div class="w-16 h-16 rounded-2xl bg-gradient-to-br from-[#efdf8a] to-[#ead581] mb-6 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-900" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
            </svg>
          </div>
          <h3 class="text-xl font-bold text-white mb-3">Testnet Tutorials</h3>
          <p class="text-white/70 mb-5">
            Step-by-step guides for participating in the latest blockchain testnet programs. Our detailed tutorials walk you through the entire process from setup to completion.
          </p>
          <ul class="space-y-2 mb-6">
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">100+ Detailed Testnet Tutorials</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Regular Updates for New Projects</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Beginner-Friendly Instructions</span>
            </li>
          </ul>
          <router-link to="/testnet" class="text-[#efdf8a] hover:text-white flex items-center gap-1 transition-colors">
            Explore Guides
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </router-link>
        </div>
        
        <!-- Node Validator Column -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#9ccdee]/5">
          <div class="w-16 h-16 rounded-2xl bg-gradient-to-br from-[#9ccdee] to-[#b4bfeb] mb-6 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-900" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
              <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
              <line x1="6" y1="6" x2="6.01" y2="6"></line>
              <line x1="6" y1="18" x2="6.01" y2="18"></line>
            </svg>
          </div>
          <h3 class="text-xl font-bold text-white mb-3">Node Validator Setup</h3>
          <p class="text-white/70 mb-5">
            Comprehensive guides for setting up and running validator nodes across various blockchain networks, optimized for performance and reliability.
          </p>
          <ul class="space-y-2 mb-6">
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Server Configuration Templates</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Monitoring & Maintenance Guides</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#9ccdee] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Security Best Practices</span>
            </li>
          </ul>
          <router-link to="/" class="text-[#9ccdee] hover:text-white flex items-center gap-1 transition-colors">
            Learn Setup
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </router-link>
        </div>
        
        <!-- Airdrop Column -->
        <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 transition-all hover:bg-white/10 hover:shadow-xl hover:shadow-[#efdf8a]/5">
          <div class="w-16 h-16 rounded-2xl bg-gradient-to-br from-[#efdf8a] to-[#9ccdee] mb-6 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-900" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
              <line x1="12" y1="12" x2="20" y2="7.5"></line>
              <line x1="12" y1="12" x2="12" y2="21"></line>
              <line x1="12" y1="12" x2="4" y2="7.5"></line>
            </svg>
          </div>
          <h3 class="text-xl font-bold text-white mb-3">Airdrop Opportunities</h3>
          <p class="text-white/70 mb-5">
            Stay updated on potential airdrop opportunities from testnet participation. Learn how to maximize your chances and claim rewards.
          </p>
          <ul class="space-y-2 mb-6">
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Early Notification System</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Claiming Process Guides</span>
            </li>
            <li class="flex items-start gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-[#efdf8a] mt-0.5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="text-white/80">Historical Airdrop Analysis</span>
            </li>
          </ul>
          <router-link to="/airdrops" class="text-[#efdf8a] hover:text-white flex items-center gap-1 transition-colors">
            Discover Airdrops
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </router-link>
        </div>
      </div>
      
      <!-- Statistics section -->
      <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-8 md:p-10">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-4">
          <div class="text-center">
            <div class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581] mb-2">8.570+</div>
            <div class="text-white/60 text-sm">Testnet Tutorials & Airdrop</div>
          </div>
          
          <div class="text-center">
            <div class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#9ccdee] to-[#b4bfeb] mb-2">45+</div>
            <div class="text-white/60 text-sm">Blockchain Networks</div>
          </div>
          
          <div class="text-center">
            <div class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#9ccdee] mb-2">73K+</div>
            <div class="text-white/60 text-sm">Community Members</div>
          </div>
          
          <div class="text-center">
            <div class="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#9ccdee] to-[#efdf8a] mb-2">$2.5M+</div>
            <div class="text-white/60 text-sm">Airdrop Value Claimed</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutServiceSection'
}
</script>

<style scoped>
/* Add hover animations */
.backdrop-blur-md {
  transition: all 0.3s ease;
}

.backdrop-blur-md:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .about-service-section {
    padding-top: 8rem; /* Additional space for fixed navbar on mobile */
  }
}
</style>