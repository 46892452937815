// Example Postingan Untuk Isi Command Command

export const ritualMarkdown = `### Auto Install Node Ritual

- Join Discord : [Discord](https://discord.com/invite/ritual-net)
- Deposit $15 - $25 ETH Base on Your Wallet
- Copy Code Paste Script to VPS

\`\`\`sh
bash <(curl -s https://upload.bangpateng.xyz/uploads/ritual.sh)
\`\`\`

- Click 1 dan Enter
- Submit Your Private Key Metamask (Use 0x)
- Waiting Install and Tx 1 Succes
- Go to [Basescan](http://basescan.org/)
- Paste You Wallet Address and Check New Tx
- Copy Your Smart Contract and Paste to VPS and Enter
- Done, You Get 2 tx Transactions on [Basescan](http://basescan.org/)

### Check Log

\`\`\`sh
docker logs infernet-node
\`\`\`

- Complete Guild and Claim Node Runner Role : [Guild](https://guild.xyz/ritual#!)
`;

