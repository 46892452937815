<template>
  <!-- Wrapper dengan background penuh - nuansa luar angkasa -->
  <div class="relative bg-[#0a0b1a] min-h-screen w-full overflow-hidden">
    <!-- Parallax Background Elements - efek luar angkasa -->
    <div class="absolute inset-0 z-0 pointer-events-none">
      <!-- Nebula dan galaksi -->
      <div 
        ref="parallaxBg1" 
        class="absolute -top-10 -right-10 w-96 h-96 bg-[#6442a3] rounded-full opacity-20 blur-3xl"
        :style="{ transform: `translate3d(${parallaxPos.x * 0.05}px, ${parallaxPos.y * 0.05}px, 0)` }"
      ></div>
      <div 
        ref="parallaxBg2" 
        class="absolute bottom-20 -left-20 w-[30rem] h-[30rem] bg-[#2b3079] rounded-full opacity-20 blur-3xl"
        :style="{ transform: `translate3d(${parallaxPos.x * -0.03}px, ${parallaxPos.y * -0.03}px, 0)` }"
      ></div>
      <div 
        ref="parallaxBg3" 
        class="absolute top-40 left-1/4 w-80 h-80 bg-[#0f8a91] rounded-full opacity-15 blur-3xl"
        :style="{ transform: `translate3d(${parallaxPos.x * 0.07}px, ${parallaxPos.y * 0.07}px, 0)` }"
      ></div>

      <!-- Bintang-bintang kecil -->
      <div class="stars-small"></div>
      <div class="stars-medium"></div>
      <div class="stars-large"></div>

      <!-- Planet dan objek luar angkasa -->
      <div 
        class="absolute top-20 right-1/4 w-20 h-20 bg-gradient-to-br from-[#d67eef] to-[#6442a3] rounded-full opacity-90"
        :style="{ transform: `translate3d(${parallaxPos.x * 0.1}px, ${parallaxPos.y * 0.1}px, 0)`, boxShadow: '0 0 30px rgba(214, 126, 239, 0.4)' }"
      ></div>
      
      <div 
        class="absolute bottom-40 right-1/5 w-28 h-28 bg-gradient-to-br from-[#efdf8a] to-[#ead581] rounded-full opacity-80"
        :style="{ transform: `translate3d(${parallaxPos.x * -0.08}px, ${parallaxPos.y * -0.08}px, 0)`, boxShadow: '0 0 40px rgba(239, 223, 138, 0.5)' }"
      >
        <!-- Cincin planet -->
        <div class="absolute inset-0 border-4 border-transparent rounded-full" 
             style="transform: rotateX(75deg); border-top: 2px solid rgba(255,255,255,0.3);">
        </div>
      </div>

      <!-- Satelit atau meteor -->
      <div 
        class="absolute top-1/3 left-20 w-4 h-4 bg-white rounded-full opacity-90"
        :style="{ transform: `translate3d(${parallaxPos.x * 0.15}px, ${parallaxPos.y * 0.15}px, 0)`, boxShadow: '0 0 15px rgba(255, 255, 255, 0.8)' }"
      ></div>

      <!-- Nebula kecil -->
      <div 
        class="absolute bottom-1/4 left-1/3 w-16 h-16 bg-gradient-to-br from-[#9ccdee] to-[#b4bfeb] rounded-full opacity-60 blur-sm"
        :style="{ transform: `translate3d(${parallaxPos.x * -0.05}px, ${parallaxPos.y * -0.07}px, 0)` }"
      ></div>
      
      <!-- Komet (elemen bergerak) -->
      <div class="comet absolute h-[2px] w-[100px] bg-gradient-to-r from-transparent to-white rounded-full"></div>
    </div>

    <!-- Navbar -->
    <NavbarMain />

    <!-- Konten Dinamis (Router View) -->
    <main class="relative z-10">
      <router-view />
    </main>

    <!-- Footer -->
    <FooterMain />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  background-color: #0a0b1a;
  color: white;
}

:root {
  --primary-yellow-light: #efdf8a;
  --primary-yellow: #ead581;
  --primary-blue-light: #b4bfeb;
  --primary-blue: #9ccdee;
}

/* Bintang-bintang */
.stars-small::after,
.stars-medium::after,
.stars-large::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(white, rgba(255, 255, 255, 0) 2px);
  border-radius: 50%;
}

.stars-small::after {
  background-size: 100px 100px;
  animation: twinkle 15s ease infinite;
}

.stars-medium::after {
  background-size: 200px 200px;
  background-position: 50px 50px;
  animation: twinkle 20s ease infinite;
}

.stars-large::after {
  background-size: 300px 300px;
  background-position: 100px 100px;
  animation: twinkle 25s ease infinite;
}

/* Animasi kedipan bintang */
@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

/* Animasi komet */
.comet {
  top: 20%;
  left: -100px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  animation: cometMove 15s linear infinite;
  transform: rotate(15deg);
}

@keyframes cometMove {
  0% { 
    left: -100px; 
    top: calc(10% + (random(1) * 80%));
  }
  100% { 
    left: calc(100% + 100px); 
    top: calc(20% + (random(1) * 60%));
  }
}
</style>

<script>
import NavbarMain from "./components/NavbarMain.vue";
import FooterMain from "./components/FooterMain.vue";

export default {
  components: {
    NavbarMain,
    FooterMain,
  },
  data() {
    return {
      parallaxPos: { x: 0, y: 0 }
    };
  },
  mounted() {
    window.addEventListener("mousemove", this.handleParallax);
    this.animateComet();
  },
  beforeUnmount() { // Vue 3 menggunakan beforeUnmount, bukan beforeDestroy
    window.removeEventListener("mousemove", this.handleParallax);
  },
  methods: {
    handleParallax(event) {
      this.parallaxPos.x = (event.clientX - window.innerWidth / 2) / 10;
      this.parallaxPos.y = (event.clientY - window.innerHeight / 2) / 10;
    },
    animateComet() {
      // Menganimasikan beberapa komet dengan jalur acak
      const comets = document.querySelectorAll('.comet');
      comets.forEach(comet => {
        // Mengatur posisi awal secara acak
        const startTop = Math.random() * 80 + 10; // 10% hingga 90% dari atas
        
        // Mengatur animasi kustom untuk setiap komet
        comet.style.animation = `none`;
        void comet.offsetWidth; // Trigger reflow
        comet.style.top = `${startTop}%`;
        comet.style.animation = `cometMove ${Math.random() * 10 + 10}s linear ${Math.random() * 15}s infinite`;
      });
    }
  }
};
</script>