// Example Postingan Untuk Isi Command Command

export const intentoMarkdown = `### Testnet Started Soon

Join Discord : [Discord](https://discord.gg/Q9PzReyw)

\`\`\`sh
Command Node Soon
\`\`\`

`;