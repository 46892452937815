// src/data/projectsData.js
// This is a centralized data store for all testnet projects
// Imports project content from separate files

// Import content files - perbaikan path import
import { arichainMarkdown } from '@/content/arichain.js';
import { intentoMarkdown } from '@/content/intento.js';
import { dillMarkdown } from '@/content/dill.js';
import { symphonyMarkdown, } from '@/content/symphony.js';
import { soundnessMarkdown, } from '@/content/soundness.js';
import { t3rnMarkdown, } from '@/content/t3rn.js';
import { ritualMarkdown, } from '@/content/ritual.js';
import { succinctMarkdown, } from '@/content/succinct.js';
import { gensynMarkdown, } from '@/content/gensyn.js';

// Import project logos and banners (you'll need to adjust the paths as needed)
import arichainLogo from '../assets/icons/arichain.png';
import intentoLogo from '../assets/icons/intento.png';
import dillLogo from '../assets/icons/dill.png';
import symphonyLogo from '../assets/icons/symphony.png';
import soundnessLogo from '../assets/icons/soundness.svg';
import t3rnLogo from '../assets/icons/t3rn.png';
import ritualLogo from '../assets/icons/ritual.svg';
import succinctLogo from '../assets/icons/succinct.svg';
import gensynLogo from '../assets/icons/gensyn.svg';

// Define social media icon components
const WebsiteIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
    </svg>
  `
};

const GithubIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.342-3.369-1.342-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.202 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.933.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.416 22 12c0-5.523-4.477-10-10-10z"/>
    </svg>
  `
};

const TwitterIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
    </svg>
  `
};

const TelegramIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M9.78 18.65L10.06 14.42L17.74 7.5C18.08 7.19 17.67 7.04 17.22 7.31L7.74 13.3L3.64 12C2.76 11.75 2.75 11.14 3.84 10.7L19.81 4.54C20.54 4.21 21.24 4.72 20.96 5.84L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.78 18.65Z"/>
    </svg>
  `
};

const DiscordIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 00-.079.036c-.21.385-.39.779-.53 1.162-1.796-.27-3.593-.27-5.325 0-.14-.38-.32-.777-.53-1.162a.077.077 0 00-.079-.036c-1.714.29-3.354.8-4.885 1.491a.07.07 0 00-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 00.03.057 20.03 20.03 0 006.031 3.056.077.077 0 00.084-.028c.462-.63.874-1.295 1.226-1.995a.076.076 0 00-.041-.106 13.209 13.209 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.372.292a.077.077 0 01-.006.127 12.412 12.412 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.963 19.963 0 006.032-3.055.077.077 0 00.032-.057c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.029zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
    </svg>
  `
};

const DocsIcon = {
  template: `
    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
      <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 18H8V16H16V18ZM16 14H8V12H16V14ZM13 9V3.5L18.5 9H13Z"/>
    </svg>
  `
};

// Central project data configuration
export const projectsData = [

// succinct Project
{
  name: "Gensyn",
  slug: "gensyn",
  logoUrl: gensynLogo,
  status: "Active",
  network: "EVM",
  networkIcon: require('../assets/icons/gensyn.svg'),
  description: "The network for machine intelligence.",
  socialLinks: {
    website: { url: "https://www.gensyn.ai/", icon: WebsiteIcon },
    github: { url: "https://github.com/gensyn-ai/rl-swarm", icon: GithubIcon },
    twitter: { url: "https://x.com/gensynai", icon: TwitterIcon },
    telegram: { url: "https://t.me/", icon: TelegramIcon },
    discord: { url: "https://discord.gg/bgyDTy39jZ", icon: DiscordIcon },
    docs: { url: "https://www.gensyn.ai/", icon: DocsIcon }
  },
  fundraising: {
    amount: "$43M",
    tokens: [
      { 
        name: "paradigm", 
        logo: require('../assets/investor/paradigm.png'), 
        link: "https://www.paradigm.xyz/" 
      },
      { 
        name: "Geometry", 
        logo: require('../assets/investor/Geometry.png'), 
        link: "https://www.geometrydao.xyz/" 
      },
      { 
        name: "Robot Ventures", 
        logo: require('../assets/investor/Robot Ventures.png'), 
        link: "https://robvc.com/" 
      },
      { 
        name: "ZKV", 
        logo: require('../assets/investor/ZKV.png'), 
        link: "https://zkvalidator.com/" 
      },
      { 
        name: "Bankless Ventures", 
        logo: require('../assets/investor/Bankless Ventures.png'), 
        link: "https://www.bankless.ventures/" 
      }
    ]
  },
  systemRequirements: {
    "CPU": "4 cores arm64 or amd64",
    "RAM": "16 GB RAM",
    "OS": "Ubuntu 24.04",
    "CUDA Devices": "RTX 3090, RTX 4090, A100, H100",
    "Storage": "500 GB SSD"
  },
  rewards: "To be determined. Potential token allocation based on performance and participation.",
  markdown: gensynMarkdown, // Using imported content
  requirements: ["Node", "VPS", "Validator", "Data Availability"],
  participants: "7,246",
  tagline: "Layer 1 for Trading Applications"
},

   // succinct Project
   {
    name: "Succinct",
    slug: "succinct",
    logoUrl: succinctLogo,
    status: "Active",
    network: "EVM",
    networkIcon: require('../assets/icons/succinct.svg'),
    description: "Prove theworld’s software Generate proofs at light speed with SP1 and our decentralized prover network.",
    socialLinks: {
      website: { url: "https://www.succinct.xyz/", icon: WebsiteIcon },
      github: { url: "https://github.com/succinctlabs", icon: GithubIcon },
      twitter: { url: "https://x.com/SuccinctLabs", icon: TwitterIcon },
      telegram: { url: "https://t.me/", icon: TelegramIcon },
      discord: { url: "https://discord.gg/succinctlabs", icon: DiscordIcon },
      docs: { url: "https://docs.succinct.xyz/", icon: DocsIcon }
    },
    fundraising: {
      amount: "$55M",
      tokens: [
        { 
          name: "paradigm", 
          logo: require('../assets/investor/paradigm.png'), 
          link: "https://www.paradigm.xyz/" 
        },
        { 
          name: "Geometry", 
          logo: require('../assets/investor/Geometry.png'), 
          link: "https://www.geometrydao.xyz/" 
        },
        { 
          name: "Robot Ventures", 
          logo: require('../assets/investor/Robot Ventures.png'), 
          link: "https://robvc.com/" 
        },
        { 
          name: "ZKV", 
          logo: require('../assets/investor/ZKV.png'), 
          link: "https://zkvalidator.com/" 
        },
        { 
          name: "Bankless Ventures", 
          logo: require('../assets/investor/Bankless Ventures.png'), 
          link: "https://www.bankless.ventures/" 
        }
      ]
    },
    systemRequirements: {
      "CPU": "4 cores",
      "RAM": "16 GB RAM",
      "OS": "Ubuntu 24.04",
      "Storage": "500 GB SSD"
    },
    rewards: "To be determined. Potential token allocation based on performance and participation.",
    markdown: succinctMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Data Availability"],
    participants: "7,246",
    tagline: "Layer 1 for Trading Applications"
  },

  // Rituasl Project
   {
    name: "Ritual",
    slug: "ritual",
    logoUrl: ritualLogo,
    status: "Active",
    network: "EVM",
    networkIcon: require('../assets/icons/ritual.svg'),
    description: "Ritual brings AI on-chain We’re building a way for any protocol, application, or smart contract to integrate AI models with a few lines of code.",
    socialLinks: {
      website: { url: "https://ritual.net/", icon: WebsiteIcon },
      github: { url: "https://github.com/ritual-net", icon: GithubIcon },
      twitter: { url: "https://x.com/ritualnet", icon: TwitterIcon },
      telegram: { url: "https://t.me/", icon: TelegramIcon },
      discord: { url: "https://discord.com/invite/ritual-net", icon: DiscordIcon },
      docs: { url: "https://ritual.academy/nodes/setup/", icon: DocsIcon }
    },
    fundraising: {
      amount: "$25M",
      tokens: [
      ]
    },
    systemRequirements: {
      "CPU": "4 cores",
      "RAM": "16 GB RAM",
      "OS": "Ubuntu 24.04",
      "Storage": "500 GB SSD"
    },
    rewards: "To be determined. Potential token allocation based on performance and participation.",
    markdown: ritualMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Data Availability"],
    participants: "7,246",
    tagline: "Layer 1 for Trading Applications"
  },
  
  // Soundness Project
    {
      name: "Soundness",
      slug: "soundness",
      logoUrl: soundnessLogo,
      status: "Active",
      network: "Sui",
      networkIcon: require('../assets/icons/soundness.svg'),
      description: "Soundness is more than just a vision—it’s a movement. We are building an Internet where your data isn’t just secure; it’s a valuable asset you can benefit from. We’re setting a new standard: combining verifiability, profitability, and privacy, powered by cutting-edge technology.",
      socialLinks: {
        website: { url: "https://soundness.xyz/", icon: WebsiteIcon },
        github: { url: "https://github.com/SoundnessLabs", icon: GithubIcon },
        twitter: { url: "https://x.com/SoundnessLabs", icon: TwitterIcon },
        telegram: { url: "https://t.me/", icon: TelegramIcon },
        discord: { url: "https://discord.gg/nG7eKBAm", icon: DiscordIcon },
        docs: { url: "https://soundness.xyz/blog", icon: DocsIcon }
      },
      fundraising: {
        amount: "$TBA",
        tokens: [
        ]
      },
      systemRequirements: {
        "CPU": "2 cores",
        "RAM": "6 GB RAM",
        "OS": "Ubuntu 22.04",
        "Storage": "50 GB SSD"
      },
      rewards: "To be determined. Potential token allocation based on performance and participation.",
      markdown: soundnessMarkdown, // Using imported content
      requirements: ["Node", "VPS", "Validator", "Data Availability"],
      participants: "11,246",
      tagline: "Layer 1 for Trading Applications"
    },
  {
    name: "Arichain",
    slug: "arichain",
    logoUrl: arichainLogo,
    status: "Active",
    network: "IBC",
    networkIcon: require('../assets/icons/arichain.png'),
    description: "Zero Setup. Zero Headache. Low Cost. 300,000 TPS to power your dApps effortlessly Join Ecosystem and shape the decentralized future!.",
    socialLinks: {
      website: { url: "https://arichain.com/", icon: WebsiteIcon },
      github: { url: "https://arichain.gitbook.io/arichain", icon: GithubIcon },
      twitter: { url: "https://x.com/Arichain_", icon: TwitterIcon },
      telegram: { url: "https://t.me/Arichain_official", icon: TelegramIcon },
      discord: { url: "https://discord.com/invite/Arichain", icon: DiscordIcon },
      docs: { url: "https://arichain.gitbook.io/arichain/for-user/quick-start", icon: DocsIcon }
    },
    fundraising: {
      amount: "$2.00M",
      tokens: [
        { name: "Bitcoin", logo: "https://img.cryptorank.io/funds/60x60.find_satoshi_lab1679664061415.png", link: "https://www.coingecko.com/en/coins/bitcoin" },
        { name: "Ethereum", logo: "https://img.cryptorank.io/funds/60x60.santiago_r_santos1678783357863.png", link: "https://www.coingecko.com/en/coins/ethereum" },
        { name: "Solana", logo: "https://img.cryptorank.io/funds/60x60.modular_capital1675764017903.png", link: "https://www.coingecko.com/en/coins/solana" }
      ]
    },
    systemRequirements: {
      "CPU": "2 cores",
      "RAM": "8 GB RAM",
      "OS": "Ubuntu 20.04+",
      "Storage": "200 GB SSD"
    },
    rewards: "TBA.",
    markdown: arichainMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Staking"],
    participants: "2,246",
    tagline: "Arichain = L1 = Gamechanger = Ants Cult Community"
  },

    // t3rn Project
    {
      name: "T3rn",
      slug: "t3rn",
      logoUrl: t3rnLogo,
      status: "Active",
      network: "EVM",
      networkIcon: require('../assets/icons/t3rn.png'),
      description: "Modular interoperability Superior swapping for users, powerful modularity for builders.",
      socialLinks: {
        website: { url: "https://www.t3rn.io/", icon: WebsiteIcon },
        github: { url: "https://github.com/SoundnessLabs", icon: GithubIcon },
        twitter: { url: "https://x.com/t3rn_io", icon: TwitterIcon },
        telegram: { url: "https://t.me/T3RN_official", icon: TelegramIcon },
        discord: { url: "https://discord.com/invite/S5kHFQTtp6", icon: DiscordIcon },
        docs: { url: "https://docs.t3rn.io/intro", icon: DocsIcon }
      },
      fundraising: {
        amount: "$6.5M",
        tokens: [
          { 
            name: "Polychain", 
            logo: require('../assets/investor/polychain.png'), 
            link: "https://polychain.capital/" 
          },
          { 
            name: "HTX Ventures", 
            logo: require('../assets/investor/htxventures.png'), 
            link: "https://www.htx.com/en-us/ventures" 
          },
          { 
            name: "Lemniscap", 
            logo: require('../assets/investor/Lemniscap.png'), 
            link: "https://lemniscap.com/" 
          },
          { 
            name: "Figment Ventures", 
            logo: require('../assets/investor/FigmentCapital.png'), 
            link: "https://www.figment.io/" 
          },
          { 
            name: "D1 Ventures", 
            logo: require('../assets/investor/D1Ventures.png'), 
            link: "https://www.d1.ventures/" 
          },
          { 
            name: "Blockchange", 
            logo: require('../assets/investor/BlockchangeVentures.png'), 
            link: "https://blockchange.vc/" 
          }
        ]
      },
      systemRequirements: {
        "CPU": "4 cores",
        "RAM": "8 GB RAM",
        "OS": "Ubuntu 22.04",
        "Storage": "100 GB SSD"
      },
      rewards: "To be determined. Potential token allocation based on performance and participation.",
      markdown: t3rnMarkdown, // Using imported content
      requirements: ["Node", "VPS", "Validator", "Data Availability"],
      participants: "11,246",
      tagline: "Modular interoperability Superior swapping for users, powerful modularity for builders."
    },
  {
    name: "Arichain",
    slug: "arichain",
    logoUrl: arichainLogo,
    status: "Active",
    network: "IBC",
    networkIcon: require('../assets/icons/arichain.png'),
    description: "Zero Setup. Zero Headache. Low Cost. 300,000 TPS to power your dApps effortlessly Join Ecosystem and shape the decentralized future!.",
    socialLinks: {
      website: { url: "https://arichain.com/", icon: WebsiteIcon },
      github: { url: "https://arichain.gitbook.io/arichain", icon: GithubIcon },
      twitter: { url: "https://x.com/Arichain_", icon: TwitterIcon },
      telegram: { url: "https://t.me/Arichain_official", icon: TelegramIcon },
      discord: { url: "https://discord.com/invite/Arichain", icon: DiscordIcon },
      docs: { url: "https://arichain.gitbook.io/arichain/for-user/quick-start", icon: DocsIcon }
    },
    fundraising: {
      amount: "$2.00M",
      tokens: [
        { name: "Bitcoin", logo: "https://img.cryptorank.io/funds/60x60.find_satoshi_lab1679664061415.png", link: "https://www.coingecko.com/en/coins/bitcoin" },
        { name: "Ethereum", logo: "https://img.cryptorank.io/funds/60x60.santiago_r_santos1678783357863.png", link: "https://www.coingecko.com/en/coins/ethereum" },
        { name: "Solana", logo: "https://img.cryptorank.io/funds/60x60.modular_capital1675764017903.png", link: "https://www.coingecko.com/en/coins/solana" }
      ]
    },
    systemRequirements: {
      "CPU": "2 cores",
      "RAM": "8 GB RAM",
      "OS": "Ubuntu 20.04+",
      "Storage": "200 GB SSD"
    },
    rewards: "TBA.",
    markdown: arichainMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Staking"],
    participants: "2,246",
    tagline: "Arichain = L1 = Gamechanger = Ants Cult Community"
  },
  
  // Intento Project
  {
    name: "Intento",
    slug: "intento",
    logoUrl: intentoLogo,
    status: "Upcoming",
    network: "IBC",
    networkIcon: require('../assets/icons/intento.png'),
    description: "An interoperable smart contract platform built on Cosmos with CosmWasm. Run a validator node and earn testnet points.",
    socialLinks: {
      website: { url: "https://intento.network/", icon: WebsiteIcon },
      github: { url: "https://github.com/intento-network", icon: GithubIcon },
      twitter: { url: "https://twitter.com/IntentoCosmos", icon: TwitterIcon },
      telegram: { url: "https://t.me/intento_community", icon: TelegramIcon },
      discord: { url: "https://discord.gg/intento", icon: DiscordIcon },
      docs: { url: "https://docs.intento.network/", icon: DocsIcon }
    },
    fundraising: {
      amount: "$TBA",
      tokens: [
        { name: "Cosmos", logo: "https://img.cryptorank.io/funds/60x60.cosmos1675764017903.png", link: "https://www.coingecko.com/en/coins/cosmos" }
      ]
    },
    systemRequirements: {
      "CPU": "4 cores",
      "RAM": "16 GB RAM",
      "OS": "Ubuntu 20.04+",
      "Storage": "500 GB SSD"
    },
    rewards: "Points-based system with potential airdrop to most active testnet participants.",
    markdown: intentoMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Staking"],
    participants: "203",
    tagline: "Smart Contracts Platform for Cosmos"
  },
  
  // Dill Project
  {
    name: "Dill",
    slug: "dill",
    logoUrl: dillLogo,
    status: "Active",
    network: "IBC",
    networkIcon: require('../assets/icons/dill.png'),
    description: "The Most Scalable Data Availability Network Dill is the first modular network fully compatible with the Danksharding roadmap.",
    socialLinks: {
      website: { url: "https://dill.network/", icon: WebsiteIcon },
      github: { url: "https://github.com/dill-labs", icon: GithubIcon },
      twitter: { url: "https://twitter.com/dill_network", icon: TwitterIcon },
      telegram: { url: "https://t.me/dill_network", icon: TelegramIcon },
      discord: { url: "https://discord.gg/dill", icon: DiscordIcon },
      docs: { url: "https://docs.dill.network/", icon: DocsIcon }
    },
    fundraising: {
      amount: "$TBA",
      tokens: [
        { name: "Ethereum", logo: "https://img.cryptorank.io/funds/60x60.santiago_r_santos1678783357863.png", link: "https://www.coingecko.com/en/coins/ethereum" }
      ]
    },
    systemRequirements: {
      "CPU": "8 cores",
      "RAM": "32 GB RAM",
      "OS": "Ubuntu 22.04",
      "Storage": "1 TB SSD"
    },
    rewards: "To be determined. Potential token allocation based on performance and participation.",
    markdown: dillMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Data Availability"],
    participants: "11,246",
    tagline: "Layer 1 for Trading Applications"
  },

  // Symphony Project

  {
    name: "Symphony",
    slug: "symphony",
    logoUrl: symphonyLogo,
    status: "Active",
    network: "IBC",
    networkIcon: require('../assets/icons/symphony.png'),
    description: "Development company for the Symphony blockchain.  We provide stablecoins for every currency, all yield-bearing, all gas-payable.",
    socialLinks: {
      website: { url: "https://orchestralabs.org/", icon: WebsiteIcon },
      github: { url: "https://github.com/Orchestra-Labs", icon: GithubIcon },
      twitter: { url: "https://x.com/orchestra_labs", icon: TwitterIcon },
      telegram: { url: "https://t.me/+xFieHCYYyx41NGQx", icon: TelegramIcon },
      discord: { url: "https://discord.gg/symphony-1162823265975279636", icon: DiscordIcon },
      docs: { url: "https://orchestralabs.org/documentation", icon: DocsIcon }
    },
    fundraising: {
      amount: "$TBA",
      tokens: [
        { name: "Ethereum", logo: "https://img.cryptorank.io/funds/60x60.santiago_r_santos1678783357863.png", link: "https://www.coingecko.com/en/coins/ethereum" }
      ]
    },
    systemRequirements: {
      "CPU": "2 cores",
      "RAM": "8 GB RAM",
      "OS": "Ubuntu 22.04",
      "Storage": "100 GB SSD"
    },
    rewards: "To be determined. Potential token allocation based on performance and participation.",
    markdown: symphonyMarkdown, // Using imported content
    requirements: ["Node", "VPS", "Validator", "Data Availability"],
    participants: "5,246",
    tagline: "SYMPHONY The Stablecoin Chain"
  }
];