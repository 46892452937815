// Example Postingan Untuk Isi Command Command

export const soundnessMarkdown = `### Auto install

\`\`\`sh
bash <(curl -s https://upload.bangpateng.xyz/uploads/soundness.sh)
\`\`\`

- Backup Pharse
- Submit Password (Free)
- Copy Public Key
- Send Public Key ke Server [Discord](https://discord.gg/nG7eKBAm) Server #Testnet-Access : !access Your-Public-Key
- Done
`;


