<template>
  <section class="contact-us-section py-16 md:py-24 mt-16 md:mt-20 px-6 relative overflow-hidden">
    <!-- Background decorative elements -->
    <div class="absolute inset-0 -z-10">
      <div class="absolute left-0 top-1/3 w-72 h-72 bg-[#efdf8a]/5 rounded-full blur-3xl"></div>
      <div class="absolute right-0 bottom-1/3 w-80 h-80 bg-[#9ccdee]/5 rounded-full blur-3xl"></div>
    </div>
    
    <div class="container mx-auto max-w-5xl">
      <!-- Breadcrumb -->
      <nav class="text-white/70 text-sm mb-10">
        <router-link to="/" class="hover:text-blue-400 transition">Home</router-link> › 
        <span class="text-white font-semibold">Contact Us</span>
      </nav>
      
      <!-- Main header -->
      <div class="text-center mb-16">
        <h1 class="text-4xl md:text-5xl font-bold text-white mb-6">
          Get in <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581]">Touch</span>
        </h1>
        <p class="text-white/80 text-lg max-w-3xl mx-auto leading-relaxed">
          Have questions or feedback? We'd love to hear from you. Our team is here to help with any inquiries about our testnet guides or validator setup.
        </p>
      </div>
      
      <!-- Contact Content -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Contact Info Column -->
        <div class="md:col-span-1 space-y-8">
          <!-- Contact Box 1 - Email -->
          <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 transition-all hover:bg-white/10">
            <div class="flex items-start gap-4">
              <div class="w-12 h-12 rounded-xl bg-[#efdf8a]/10 flex items-center justify-center flex-shrink-0">
                <svg class="w-6 h-6 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
                </svg>
              </div>
              
              <div>
                <h3 class="text-lg font-semibold text-white mb-2">Email Us</h3>
                <p class="text-white/70 mb-1">General Inquiries:</p>
                <a href="mailto:info@bangpateng.xyz" class="text-[#efdf8a] hover:text-white transition-colors">info@bangpateng.xyz</a>
                
                <p class="text-white/70 mt-3 mb-1">Support:</p>
                <a href="mailto:support@bangpateng.xyz" class="text-[#efdf8a] hover:text-white transition-colors">support@bangpateng.xyz</a>
              </div>
            </div>
          </div>
          
          <!-- Contact Box 2 - Social -->
          <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 transition-all hover:bg-white/10">
            <div class="flex items-start gap-4">
              <div class="w-12 h-12 rounded-xl bg-[#9ccdee]/10 flex items-center justify-center flex-shrink-0">
                <svg class="w-6 h-6 text-[#9ccdee]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/>
                </svg>
              </div>
              
              <div>
                <h3 class="text-lg font-semibold text-white mb-2">Connect with Us</h3>
                <p class="text-white/70 mb-4">Join our community channels:</p>
                
                <div class="grid grid-cols-2 gap-3">
                  <a href="https://t.me/bang_pateng" target="_blank" class="flex items-center gap-2 text-white/80 hover:text-[#efdf8a] transition-colors">
                    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M9.78 18.65L10.06 14.42L17.74 7.5C18.08 7.19 17.67 7.04 17.22 7.31L7.74 13.3L3.64 12C2.76 11.75 2.75 11.14 3.84 10.7L19.81 4.54C20.54 4.21 21.24 4.72 20.96 5.84L18.24 18.65C18.05 19.56 17.5 19.78 16.74 19.36L12.6 16.3L10.61 18.23C10.38 18.46 10.19 18.65 9.78 18.65Z"/>
                    </svg>
                    Telegram
                  </a>
                  
                  <a href="https://twitter.com/bangpateng_" target="_blank" class="flex items-center gap-2 text-white/80 hover:text-[#efdf8a] transition-colors">
                    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                    </svg>
                    Twitter
                  </a>
                  
                  <a href="https://discord.gg/mukidihajar" target="_blank" class="flex items-center gap-2 text-white/80 hover:text-[#efdf8a] transition-colors">
                    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 00-.079.036c-.21.385-.39.779-.53 1.162-1.796-.27-3.593-.27-5.325 0-.14-.38-.32-.777-.53-1.162a.077.077 0 00-.079-.036c-1.714.29-3.354.8-4.885 1.491a.07.07 0 00-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 00.03.057 20.03 20.03 0 006.031 3.056.077.077 0 00.084-.028c.462-.63.874-1.295 1.226-1.995a.076.076 0 00-.041-.106 13.209 13.209 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.372.292a.077.077 0 01-.006.127 12.412 12.412 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.963 19.963 0 006.032-3.055.077.077 0 00.032-.057c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.029z"/>
                    </svg>
                    Discord
                  </a>
                  
                  <a href="https://github.com/bangpateng" target="_blank" class="flex items-center gap-2 text-white/80 hover:text-[#efdf8a] transition-colors">
                    <svg class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.342-3.369-1.342-.454-1.155-1.11-1.462-1.11-1.462-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.202 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.933.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.138 20.161 22 16.416 22 12c0-5.523-4.477-10-10-10z"/>
                    </svg>
                    GitHub
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Contact Box 3 - Response Time -->
          <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 transition-all hover:bg-white/10">
            <div class="flex items-start gap-4">
              <div class="w-12 h-12 rounded-xl bg-[#efdf8a]/10 flex items-center justify-center flex-shrink-0">
                <svg class="w-6 h-6 text-[#efdf8a]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                  <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
                </svg>
              </div>
              
              <div>
                <h3 class="text-lg font-semibold text-white mb-2">Response Time</h3>
                <p class="text-white/70">
                  We usually respond to inquiries within 24-48 hours during business days. For urgent matters, please reach out through Discord or Telegram.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Contact Form Column -->
        <div class="md:col-span-2">
          <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-6 md:p-8">
            <h2 class="text-xl font-bold text-white mb-6">Send us a Message</h2>
            
            <form @submit.prevent="submitForm" class="space-y-6">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Name Input -->
                <div>
                  <label for="name" class="block text-white/80 text-sm font-medium mb-2">Your Name</label>
                  <input 
                    type="text" 
                    id="name" 
                    v-model="formData.name" 
                    class="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/40 focus:outline-none focus:border-[#efdf8a]/50 focus:ring-1 focus:ring-[#efdf8a]/50"
                    placeholder="John Doe"
                    required
                  >
                </div>
                
                <!-- Email Input -->
                <div>
                  <label for="email" class="block text-white/80 text-sm font-medium mb-2">Email Address</label>
                  <input 
                    type="email" 
                    id="email" 
                    v-model="formData.email" 
                    class="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/40 focus:outline-none focus:border-[#efdf8a]/50 focus:ring-1 focus:ring-[#efdf8a]/50"
                    placeholder="john@example.com"
                    required
                  >
                </div>
              </div>
              
              <!-- Subject Input -->
              <div>
                <label for="subject" class="block text-white/80 text-sm font-medium mb-2">Subject</label>
                <input 
                  type="text" 
                  id="subject" 
                  v-model="formData.subject" 
                  class="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/40 focus:outline-none focus:border-[#efdf8a]/50 focus:ring-1 focus:ring-[#efdf8a]/50"
                  placeholder="How can we help you?"
                  required
                >
              </div>
              
              <!-- Message Input -->
              <div>
                <label for="message" class="block text-white/80 text-sm font-medium mb-2">Message</label>
                <textarea 
                  id="message" 
                  v-model="formData.message" 
                  rows="6" 
                  class="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-white/40 focus:outline-none focus:border-[#efdf8a]/50 focus:ring-1 focus:ring-[#efdf8a]/50"
                  placeholder="Enter your message here..."
                  required
                ></textarea>
              </div>
              
              <!-- Submit Button -->
              <div class="flex justify-end">
                <button 
                  type="submit" 
                  class="px-8 py-3 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all duration-300 flex items-center gap-2"
                  :disabled="isSubmitting"
                >
                  <span>{{ isSubmitting ? 'Sending...' : 'Send Message' }}</span>
                  <svg v-if="!isSubmitting" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                  </svg>
                  <svg v-else class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </button>
              </div>
            </form>
            
            <!-- Success Message (conditionally shown) -->
            <div v-if="formSubmitted" class="mt-6 p-4 bg-[#efdf8a]/10 border border-[#efdf8a]/30 rounded-lg text-white">
              <div class="flex items-center gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[#efdf8a]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                <span>Your message has been sent successfully! We'll get back to you soon.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- FAQ Section -->
      <div class="mt-16">
        <h2 class="text-2xl font-bold text-white mb-8 text-center">Frequently Asked Questions</h2>
        
        <div class="space-y-6 max-w-3xl mx-auto">
          <div v-for="(faq, index) in faqs" :key="index" class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl overflow-hidden">
            <button 
              @click="toggleFAQ(index)" 
              class="w-full p-6 text-left flex justify-between items-center"
            >
              <h3 class="text-lg font-medium text-white">{{ faq.question }}</h3>
              <svg 
                class="w-5 h-5 text-white/60 transition-transform" 
                :class="{ 'rotate-180': faq.isOpen }"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            
            <div 
              v-show="faq.isOpen"
              class="px-6 pb-6 text-white/70"
            >
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactUsComponent',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      isSubmitting: false,
      formSubmitted: false,
      faqs: [
        {
          question: "How do I get started with node validation?",
          answer: "To get started with node validation, browse our testnet guides section where you'll find step-by-step tutorials for various blockchain networks. Each guide includes hardware requirements, setup instructions, and common troubleshooting tips.",
          isOpen: false
        },
        {
          question: "Do you provide support for node setup issues?",
          answer: "Yes, we offer community support through our Discord and Telegram channels. For specific technical issues, you can use the contact form on this page or email our support team directly.",
          isOpen: false
        },
        {
          question: "How do I know if I'm eligible for airdrops?",
          answer: "Eligibility for airdrops varies by project. We provide information about known airdrop criteria in our testnet guides, but it's important to note that project teams make final decisions about airdrop distribution. Being an active and engaged validator generally improves your chances.",
          isOpen: false
        },
        {
          question: "How often do you update your guides?",
          answer: "We update our guides whenever there are significant changes to the networks or when new information becomes available. Most guides are reviewed at least monthly, and all guides include a 'Last Updated' timestamp so you know how recent the information is.",
          isOpen: false
        }
      ]
    }
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true;
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Reset form and show success message
      this.formData = {
        name: '',
        email: '',
        subject: '',
        message: ''
      };
      
      this.isSubmitting = false;
      this.formSubmitted = true;
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        this.formSubmitted = false;
      }, 5000);
    },
    toggleFAQ(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    }
  }
}
</script>

<style scoped>
/* Add any additional custom styles here */
</style>