<template>
  <section class="hero-section relative overflow-hidden py-32 md:py-40">
    <!-- Efek cahaya (light effects) dengan opacity rendah -->
    <div class="absolute inset-0 z-0 pointer-events-none overflow-hidden">
      <!-- Light blob 1 -->
      <div class="absolute -top-40 -right-20 w-[500px] h-[500px] bg-gradient-to-br from-[#efdf8a]/20 to-[#ead581]/10 rounded-full blur-3xl"></div>
      
      <!-- Light blob 2 -->
      <div class="absolute -bottom-40 -left-20 w-[600px] h-[600px] bg-gradient-to-tr from-[#9ccdee]/15 to-[#b4bfeb]/10 rounded-full blur-3xl"></div>
      
      <!-- Floating particles -->
      <div class="particles absolute inset-0"></div>
      
      <!-- Subtle grid pattern -->
      <div class="absolute inset-0 bg-grid-pattern opacity-[0.03]"></div>
    </div>

    <div class="container mx-auto px-6 md:px-8 relative z-10">
      <div class="flex flex-col lg:flex-row items-center justify-between gap-16">
        <!-- Hero content -->
        <div class="lg:w-1/2 space-y-10">
          <!-- Badge / Tagline -->
          <div class="inline-flex items-center px-4 py-2 backdrop-blur-md bg-gradient-to-r from-[#efdf8a]/10 to-[#ead581]/10 border border-[#efdf8a]/20 rounded-full">
            <span class="text-sm font-medium text-[#ead581]">{{ tagline }}</span>
          </div>
          
          <!-- Main heading with gradient text -->
          <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-white">
            {{ title }} 
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#efdf8a] to-[#ead581]">{{ accentTitle }}</span>
          </h1>
          
          <!-- Description with glass effect -->
          <div class="backdrop-blur-sm bg-white/5 border border-white/10 rounded-xl p-6">
            <p class="text-lg text-white/80 leading-relaxed">
              {{ description }}
            </p>
          </div>
          
          <!-- CTA Buttons -->
          <!-- CTA Buttons -->
<div class="flex flex-wrap gap-5 pt-2">
  <a href="/testnet" class="px-8 py-4 bg-gradient-to-r from-[#efdf8a] to-[#ead581] text-gray-900 font-medium rounded-full hover:shadow-lg hover:shadow-[#efdf8a]/20 transition-all duration-300 flex items-center gap-2">
    {{ primaryButtonText }}
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
    </svg>
  </a>
  <a href="https://t.me/bangpateng_airdrop" class="px-8 py-4 backdrop-blur-sm bg-white/5 border border-white/10 text-white hover:bg-white/10 font-medium rounded-full transition-all duration-300">
    {{ secondaryButtonText }}
  </a>
</div>
          
          <!-- Social proof / Stats -->
          <div class="flex flex-wrap gap-16 pt-8">
            <div v-for="(stat, index) in stats" :key="index" class="text-center">
              <div class="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#9ccdee] to-[#b4bfeb]">{{ stat.value }}</div>
              <div class="text-sm text-white/60 mt-1">{{ stat.label }}</div>
            </div>
          </div>
        </div>
        
        <!-- Hero image area (with glass effect) -->
        <div class="lg:w-1/2 relative mt-12 lg:mt-0">
          <div class="relative backdrop-blur-md bg-white/5 border border-white/10 rounded-2xl p-6 shadow-xl">
            <img 
              :src="require('@/assets/logo.png')" 
              alt="Hero Illustration" 
              class="w-full h-auto rounded-xl z-10 relative"
            />
            
            <!-- Decorative elements -->
            <div class="absolute -top-5 -left-5 w-10 h-10 rounded-full bg-gradient-to-r from-[#efdf8a] to-[#ead581]"></div>
            <div class="absolute -bottom-5 -right-5 w-10 h-10 rounded-full bg-gradient-to-r from-[#9ccdee] to-[#b4bfeb]"></div>
            
            <!-- Floating card 1 -->
            <div class="absolute -top-8 -right-8 backdrop-blur-lg bg-white/10 border border-white/20 p-5 rounded-xl shadow-lg animate-float-slow">
              <div class="flex items-center gap-4">
                <div class="w-12 h-12 rounded-full bg-gradient-to-r from-[#efdf8a] to-[#ead581] flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586l3.293-3.293A1 1 0 0114 6h-2z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div>
                  <div class="font-semibold text-white text-lg">{{ floatingCard1.title }}</div>
                  <div class="text-sm text-white/60">{{ floatingCard1.subtitle }}</div>
                </div>
              </div>
            </div>
            
            <!-- Floating card 2 -->
            <div class="absolute -bottom-8 -left-8 backdrop-blur-lg bg-white/10 border border-white/20 p-5 rounded-xl shadow-lg animate-float-medium">
              <div class="flex items-center gap-4">
                <div class="w-12 h-12 rounded-full bg-gradient-to-r from-[#9ccdee] to-[#b4bfeb] flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                </div>
                <div>
                  <div class="font-semibold text-white text-lg">{{ floatingCard2.title }}</div>
                  <div class="text-sm text-white/60">{{ floatingCard2.subtitle }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TransparentHero',
  data() {
    return {
      tagline: 'Airdrop, Node Running & Testnets',
      title: 'Your Gateway to Early-Stage',
      accentTitle: 'Blockchain Projects',
      description: 'Access cutting-edge testnet opportunities, setup validators easily, and discover promising blockchain projects before mainnet launch. Join our community of node operators.',
      primaryButtonText: 'Explore Testnets',
      secondaryButtonText: 'Join Community',
      heroImage: '/images/blockchain-illustration.png', // Ganti dengan path gambar Anda
      stats: [
        { value: '21+', label: 'Active Projects' },
        { value: '73K+', label: 'Community Members' },
        { value: '24/7', label: 'Support' }
      ],
      floatingCard1: {
        title: 'Growing Activity',
        subtitle: '+27% this month'
      },
      floatingCard2: {
        title: 'Active Community',
        subtitle: '73,000+ Members'
      }
    }
  }
}
</script>

<style scoped>
/* Animasi floating elements */
@keyframes float-slow {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
}

@keyframes float-medium {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.animate-float-slow {
  animation: float-slow 8s ease-in-out infinite;
}

.animate-float-medium {
  animation: float-medium 6s ease-in-out infinite;
  animation-delay: 1s;
}

/* Grid pattern background */
.bg-grid-pattern {
  background-size: 30px 30px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
}

/* Particles animation */
.particles {
  background-image: radial-gradient(circle, white 1px, transparent 1px);
  background-size: 50px 50px;
}

/* For dark themes, set the parent background color to match your app's background */
.hero-section {
  /* background: transparent ensures it works with your existing background */
  background: transparent;
}

/* Mobile responsiveness adjustments */
@media (max-width: 768px) {
  .hero-section {
    padding-top: 8rem; /* Extra top padding to account for fixed navbar */
    padding-bottom: 4rem;
  }
  
  h1 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  
  .container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  
  /* Make buttons full width on mobile */
  .flex.flex-wrap.gap-5 {
    flex-direction: column;
  }
  
  button {
    width: 100%;
    justify-content: center;
  }
  
  /* Better spacing for stats on mobile */
  .flex.flex-wrap.gap-16 {
    justify-content: space-around;
    gap: 1.5rem;
  }
}
</style>