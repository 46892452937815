// Example Postingan Untuk Isi Command Command

export const t3rnMarkdown = `### Prepare Token Testnet

- Swap ETH Mainnet to Sepolia : [https://testnetbridge.com/sepolia](https://testnetbridge.com/sepolia)
- Sepolia to ARB sep : [https://bridge.arbitrum.io/](https://bridge.arbitrum.io/)
- to BASE SEPO : [https://superbridge.app/base-sepolia](https://superbridge.app/base-sepolia)
- to OPT SEPO : [https://superbridge.app/op-sepolia](https://superbridge.app/op-sepolia)
- to BLAST SEPO send to address : 0xc644cc19d2a9388b71dd1dede07cffc73237dca8

### Auto Install Executor

\`\`\`sh
bash <(curl -s https://upload.bangpateng.xyz/uploads/tern.sh)
\`\`\`

- Submit Your Private Key Metamask (No 0x)

### Check Log

\`\`\`sh
sudo journalctl -u t3rn-executor.service -f
\`\`\`

- Next bridge from arbitrum sepolia to B2N/l2rn : [https://b2n.bridge.caldera.xyz/](https://b2n.bridge.caldera.xyz/)
- Swap and Check Reward On Web T3rn V2 : [https://bridge.t2rn.io/](https://bridge.t2rn.io/)
`;

