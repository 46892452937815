<!-- src/views/Privacy.vue -->
<template>
  <div class="privacy-policy-container py-20 md:py-32 mt-16 md:mt-20 px-6 max-w-5xl mx-auto">
    <!-- Header -->
    <div class="text-center mb-16">
      <h1 class="text-4xl md:text-5xl font-bold text-white mb-4">Privacy Policy</h1>
      <p class="text-white/70 text-lg">Last Updated: March 15, 2025</p>
    </div>

    <!-- Privacy Policy Content -->
    <div class="backdrop-blur-md bg-white/5 border border-white/10 rounded-xl p-8 text-white">
      <!-- Introduction -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Introduction</h2>
        <p class="text-white/80 mb-4">
          Welcome to the Privacy Policy of Bang Pateng Community. This document outlines how we collect, use, and protect your personal information when you use our website and services.
        </p>
        <p class="text-white/80">
          We are committed to ensuring the privacy and security of your personal data. By using our platform, you consent to the practices described in this policy.
        </p>
      </section>

      <!-- Information We Collect -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Information We Collect</h2>
        <p class="text-white/80 mb-4">We may collect the following types of information:</p>
        
        <h3 class="text-xl font-semibold mb-2 text-white">Personal Information</h3>
        <ul class="list-disc pl-6 text-white/80 mb-6 space-y-2">
          <li>Email address (when you subscribe to our newsletter)</li>
          <li>Wallet addresses (when connecting to our platform)</li>
          <li>Username and profile information (when creating an account)</li>
          <li>Communication data (when contacting us directly)</li>
        </ul>
        
        <h3 class="text-xl font-semibold mb-2 text-white">Technical Information</h3>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Device information</li>
          <li>Pages visited and navigation patterns</li>
          <li>Time and date of visits</li>
        </ul>
      </section>

      <!-- How We Use Your Information -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">How We Use Your Information</h2>
        <p class="text-white/80 mb-4">We use the collected information for the following purposes:</p>
        
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>To provide and maintain our services</li>
          <li>To notify you about changes to our services</li>
          <li>To allow you to participate in interactive features</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information to improve our services</li>
          <li>To monitor the usage of our services</li>
          <li>To detect, prevent, and address technical issues</li>
          <li>To provide you with news, special offers, and general information about other goods, services, and events</li>
        </ul>
      </section>

      <!-- Data Storage and Security -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Data Storage and Security</h2>
        <p class="text-white/80 mb-4">
          We prioritize the security of your personal information and implement appropriate technical and organizational measures to protect it.
        </p>
        <p class="text-white/80 mb-4">
          While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security. Any transmission of information via the internet carries inherent risks.
        </p>
        <p class="text-white/80">
          Your data may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ.
        </p>
      </section>

      <!-- Cookies and Tracking -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Cookies and Tracking Technologies</h2>
        <p class="text-white/80 mb-4">
          We use cookies and similar tracking technologies to track activity on our platform and hold certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier.
        </p>
        <p class="text-white/80 mb-4">
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.
        </p>
        <p class="text-white/80">
          We use the following types of cookies:
        </p>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li><strong>Essential cookies:</strong> Necessary for the operation of our website</li>
          <li><strong>Analytical/performance cookies:</strong> Allow us to recognize and count the number of visitors</li>
          <li><strong>Functionality cookies:</strong> Enable us to personalize content for you</li>
          <li><strong>Targeting cookies:</strong> Record your visit to our website, pages visited, and links followed</li>
        </ul>
      </section>

      <!-- Third-Party Disclosure -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Third-Party Disclosure</h2>
        <p class="text-white/80 mb-4">
          We do not sell, trade, or transfer your personally identifiable information to outside parties without your consent, except as described in this policy.
        </p>
        <p class="text-white/80">
          We may share your information with:
        </p>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Service providers who assist us in operating our website</li>
          <li>Business partners with whom we jointly offer products or services</li>
          <li>Affiliated companies</li>
          <li>Law enforcement or other governmental authorities in response to a legal request</li>
        </ul>
      </section>

      <!-- User Rights -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Your Rights</h2>
        <p class="text-white/80 mb-4">
          Depending on your location, you may have certain rights regarding your personal information:
        </p>
        <ul class="list-disc pl-6 text-white/80 space-y-2">
          <li>Right to access the personal information we hold about you</li>
          <li>Right to rectification of inaccurate information</li>
          <li>Right to erasure (the "right to be forgotten")</li>
          <li>Right to restriction of processing</li>
          <li>Right to data portability</li>
          <li>Right to object to processing</li>
          <li>Rights related to automated decision-making and profiling</li>
        </ul>
        <p class="text-white/80 mt-4">
          To exercise any of these rights, please contact us using the information provided at the end of this policy.
        </p>
      </section>

      <!-- Children's Privacy -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Children's Privacy</h2>
        <p class="text-white/80">
          Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will take steps to remove that information.
        </p>
      </section>

      <!-- Changes to This Policy -->
      <section class="mb-10">
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Changes to This Privacy Policy</h2>
        <p class="text-white/80">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <!-- Contact Information -->
      <section>
        <h2 class="text-2xl font-bold mb-4 text-[#efdf8a]">Contact Us</h2>
        <p class="text-white/80 mb-4">
          If you have any questions about this Privacy Policy, please contact us:
        </p>
        <div class="backdrop-blur-sm bg-white/5 rounded-lg p-4 inline-block">
          <p class="text-white font-medium">Bang Pateng</p>
          <p class="text-white/80">Email: privacy@bangpateng.com</p>
          <p class="text-white/80">Address: Crypto Valley, Blockchain Street 123</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo: {
    title: 'Privacy Policy - BangPateng Testnet Explorer'
  }
}
</script>

<style scoped>
/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding-top: 6rem;
  }
}

/* Component-specific styles */
section h2 {
  position: relative;
  padding-bottom: 0.75rem;
}

section h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #efdf8a, rgba(239, 223, 138, 0.5));
  border-radius: 3px;
}
</style>